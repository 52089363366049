import React from 'react';

const CircleCloseIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.7268 5.3938L6.22681 10.8938M11.7268 10.8938L6.22706 5.39355"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.8335 15.5C12.9756 15.5 16.3335 12.1421 16.3335 8C16.3335 3.85786 12.9756 0.5 8.8335 0.5C4.69136 0.5 1.3335 3.85786 1.3335 8C1.3335 12.1421 4.69136 15.5 8.8335 15.5Z"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCloseIcon;
