import { GetLocalizations } from '@client-shopify/gql/storefront/api/queries';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { DownChevronIcon, Menu } from '@ui/components/core';
import { Spinner } from '@ui/components/shared';
import { Text } from '@ui/components/shared/typography';
import env from '@ui/env';
import { useCartBuyerUpdate } from '@ui/hooks/useCartMutations';
import { useCookie } from '@ui/hooks/useCookie';
import { useCartStore } from '@ui/store.export';
import cn from '@ui/utils/cn';
import { sortBy } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

const LocalCurrencyDropdown = () => {
  const { data: localizations } = useQuery({
    queryKey: ['localizations'],
    queryFn: async () => (await GetLocalizations()).data,
  });

  const availableCountries = localizations?.localization.availableCountries || [];
  const [selectedCountry, setSelectedCountry] = React.useState<string>(env.NEXT_PUBLIC_REGION);
  const selectedCurrency = availableCountries.find((country) => country.isoCode === selectedCountry)?.currency;

  React.useEffect(() => {
    const customerCountry = localStorage.getItem('customerCountry');
    if (customerCountry) setSelectedCountry(customerCountry);
  }, []);

  const router = useRouter();

  const [loading, setLoading] = React.useState(false);
  const { CartBuyerIdentityUpdateMutation } = useCartBuyerUpdate();
  const cartId = useCartStore((state) => state?.cartId);
  const buyerIp = useCookie('shopifyBuyerIp');

  const handleClick = async (country: (typeof availableCountries)[0]) => {
    if (!cartId) {
      setSelectedCountry(country.isoCode);
      localStorage.setItem('customerCountry', country.isoCode);
      router.reload();
      return;
    }

    setLoading(true);

    try {
      await CartBuyerIdentityUpdateMutation({
        cartId,
        buyerIP: buyerIp,
        buyerIdentity: {
          countryCode: country.isoCode,
        },
      });

      setSelectedCountry(country.isoCode);
      localStorage.setItem('customerCountry', country.isoCode);
      router.reload();
    } catch {
      notifications.show({
        message: 'Failed to set the currency.',
        variant: 'danger',
        autoClose: 3000,
      });
    }

    setLoading(false);
  };

  return (
    <div className="shrink-0 flex">
      <Text className="hidden mr-2 md:block">YOU&apos;RE SHOPPING IN:</Text>
      <Menu
        zIndex={9999}
        withinPortal
        classNames={{
          dropdown: 'px-0 py-1 rounded-none shadow-none border-black bg-white shrunk:!opacity-0',
          item: 'px-3 py-1 text-[12px] rounded-none underline-offset-[6px] data-[hovered]:underline data-[hovered]:bg-white',
        }}
      >
        <Menu.Target>
          <button
            className={cn(
              'flex items-center justify-center',
              'text-[12px] font-normal space-x-1 group',
              'focus:outline-none',
            )}
          >
            {loading || !selectedCurrency ? (
              <Spinner className="size-5 border-[3px]" />
            ) : (
              <>
                <span>{`${selectedCurrency.symbol}${selectedCurrency.isoCode}`}</span>
                <DownChevronIcon className="group-data-[expanded]:rotate-180 transition" width={16} height={16} />
              </>
            )}
          </button>
        </Menu.Target>
        <Menu.Dropdown className="h-auto max-h-[400px] overscroll-contain overflow-y-auto">
          {sortBy(availableCountries, 'name').map((country) => (
            <Menu.Item
              key={country.isoCode}
              data-selected={selectedCountry === country.isoCode || undefined}
              onClick={() => handleClick(country)}
              className="data-[selected]:underline"
            >
              {country.name} ({country.currency.isoCode} {country.currency.symbol})
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default LocalCurrencyDropdown;
