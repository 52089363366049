import React from 'react';
import { Accordion, Prose } from '@ui/components/core';
import FAQAccordion from '../FAQAccordion/FAQAccordion';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';

const FAQPaymentsAndPromos = (): React.ReactElement => (
  <FAQAccordion handle="payments-and-promos">
    {useStaticContent('FAQ.PaymentsAndPromos.PaymentsAndPromos') && (
      <Accordion.Item value="account-charge">
        <Accordion.Control>Charging your account</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.PaymentsAndPromos" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.CurrencyConversion') && (
      <Accordion.Item value="currency-conversions">
        <Accordion.Control>Currency conversions</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.CurrencyConversion" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.NzGst') && (
      <Accordion.Item value="nz-gst">
        <Accordion.Control>New Zealand GST</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.NzGst" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.Ordering') && (
      <Accordion.Item value="ordering">
        <Accordion.Control>Ordering</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.Ordering" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.OrderPayment') && (
      <Accordion.Item value="order-payment">
        <Accordion.Control>Paying For Your Order</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.OrderPayment" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.InstallmentPayments') && (
      <Accordion.Item value="installment-payments">
        <Accordion.Control>Paying in installments</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.InstallmentPayments" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.StoreCreditPayments') && (
      <Accordion.Item value="store-credit-payments">
        <Accordion.Control>Paying with store credit</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.StoreCreditPayments" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.PromoCodes') && (
      <Accordion.Item value="promo-codes">
        <Accordion.Control>Promo codes</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.PromoCodes" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}

    {useStaticContent('FAQ.PaymentsAndPromos.PromoCodeNewCustomers') && (
      <Accordion.Item value="promo-code-new-customers">
        <Accordion.Control>Promo codes for new customers</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PaymentsAndPromos.PromoCodeNewCustomers" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
  </FAQAccordion>
);

export default FAQPaymentsAndPromos;
