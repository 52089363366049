import React from 'react';
import { ActionIcon, Modal, CloseIcon } from '@ui/components/core';
import { Button } from '@ui/components/shared';

type FinalSaleModalProps = {
  opened: boolean;
  onClose: () => void;
};

const FinalSaleModal = ({ opened, onClose }: FinalSaleModalProps): React.ReactElement => {
  const body = (
    <div className="p-4 pb-6 sm:p-8 sm:pb-8">
      <div className="flex flex-row items-start justify-between pb-3 border-b border-neutral-grey-300 mb-4">
        <p className="uppercase text-base font-semibold">Final Sale Details</p>
        <ActionIcon
          className="flex flex-col justify-start items-center !bg-transparent focus:outline-none"
          onClick={onClose}
        >
          <CloseIcon width={20} height={20} />
        </ActionIcon>
      </div>
      <div className="mb-4 text-base/[160%]">
        <p className="mb-5">
          Please note this item is marked as FINAL SALE and cannot be returned or exchanged unless faulty.
        </p>
        <p>
          This applies to all products marked FINAL SALE and can include products listed with a sale price, intimates,
          earrings, and more.{' '}
          <span className="sm:block sm:mt-5">
            Please see our{' '}
            <a href="/pages/returns" className="underline">
              Returns Policy{' '}
            </a>
            for more details.
          </span>
        </p>
      </div>
      <Button className="w-full md:text-[13px]" onClick={onClose}>
        Continue Shopping
      </Button>
    </div>
  );

  return (
    <Modal
      headerProps={{ pb: 0 }}
      body={body}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      withOverlay
      centered
      classNames={{
        inner: 'px-0 pb-0 flex-col justify-end sm:flex-row sm:justify-center',
        content: 'flex-none mx-auto overflow-hidden rounded-t-lg rounded-b-none sm:max-w-[28.3125rem] sm:rounded-lg',
        body: 'p-0',
      }}
    />
  );
};

export default FinalSaleModal;
