import { create } from 'zustand';

type Modal = 'search' | 'cart' | 'wishlist' | 'megamenu' | null;

const useModalStore = create<{
  openedModal: Modal;
  openModal: (modal: Modal) => void;
  closeModal: () => void;
}>((set) => ({
  openedModal: null,
  openModal: (modal: Modal) => set(() => ({ openedModal: modal })),
  closeModal: () => set(() => ({ openedModal: null })),
}));

export default useModalStore;
