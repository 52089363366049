import { type CountryCode } from '@client-shopify/gql/storefront/__generated__/graphql';
import env from '@ui/env';
import React from 'react';

export default function useCustomerCountry() {
  const [customerCountry, setCustomerCountry] = React.useState<CountryCode>();

  React.useEffect(() => {
    setCustomerCountry(getCustomerCountry());
  }, []);

  return customerCountry;
}

export function getCustomerCountry() {
  return (localStorage.getItem('customerCountry') || env.NEXT_PUBLIC_REGION) as CountryCode;
}
