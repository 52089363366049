import { z } from 'zod';

const envSchema = z.object({
  NEXT_PUBLIC_NODE_ENV: z.string().min(1),
  NEXT_PUBLIC_REGION: z.enum(['AU', 'NZ', 'US', 'GB']),
  NEXT_PUBLIC_BASE_URL: z.string().min(1),
  NEXT_PUBLIC_SEARCHSPRING_SITEID: z.string().min(1),
  NEXT_PUBLIC_SEARCHSPRING_BEACON_URL: z.string().min(1),
  NEXT_PUBLIC_BASE_SEARCHSPRING_URL: z.string().min(1),
  NEXT_PUBLIC_YOTPO_ACCOUNT_ID: z.string().min(1),
  NEXT_PUBLIC_YOTPO_APP_KEY: z.string().min(1),
  NEXT_PUBLIC_YOTPO_TMP_KEY: z.string().min(1),
  NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_SIZE_ID: z.string().min(1),
  NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_HEIGHT_ID: z.string().min(1),
  NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_FIT_ID: z.string().min(1),
  NEXT_PUBLIC_ZENDESK_ACCOUNT_SUBDOMAIN: z.string().min(1),
  HOME_INSTAGRAM_SECTION: z.boolean(),
  HOME_MIDDLE_BANNER_SECTION: z.boolean(),
  SOCIAL_LOGIN_FEATURE: z.boolean(),
  DOB_FEATURE: z.boolean(),
  PROFILE_WISHLIST_FEATURE: z.boolean(),
  PROFILE_MOBILE_DROPDOWN_FEATURE: z.boolean(),
  PRODUCT_CARD_QUICK_ADD_FEATURE: z.boolean(),
  PRODUCT_CARD_CAROUSEL_FEATURE: z.boolean(),
  PRODUCT_REVIEWS_FEATURE: z.boolean(),
  PRODUCT_REVIEWS_CREATE_FEATURE: z.boolean(),
  PRODUCT_REVIEWS_VOTE_FEATURE: z.boolean(),
  PRODUCT_REVIEWS_PAGINATION_FEATURE: z.boolean(),
  PRODUCT_BNPL_FEATURE: z.boolean(),
  COLOR_SWATCH_FEATURE: z.boolean(),
  NEWSLETTER_FEATURE: z.boolean(),
  ADDRESS_FINDER: z.boolean(),
  ORDER_DETAILS_EDIT: z.boolean(),
  ORDER_PAYMENT_DETAILS: z.boolean(),
  CART_UPSELL_FEATURE: z.boolean(),
  CONTACT_FORM_FEATURE: z.boolean(),
  NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN: z.string().regex(/[\w\-]+\.myshopify\.com/), // e.g. hellomollyfashion-nz.myshopify.com
  NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION: z.string().regex(/\d\d\d\d-\d\d/), // e.g. 2023-01
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN: z.string().min(1),
  NEXT_PUBLIC_SHOPIFY_STORE_URL: z.string().min(1),
  UNIDAYS_FEATURE: z.boolean(),
  GIFT_CARDS_FEATURE: z.boolean(),
  ABOUT_US_PREVIEW: z.boolean(),
  ABOUT_US_SCROLL_FEATURE: z.boolean(),
  WISHLIST_BOARD: z.boolean(),
  WISHLIST_PREVIEW: z.boolean(),
  PDP_DONT_MISS_OUT_FEATURE: z.boolean(),
  SIGNUP_LOGIN_PREVIEW: z.boolean(),
  RETURNS_PREVIEW: z.boolean(),
  CART_DRAWER_PREVIEW: z.boolean(),
  STORE_LOCATOR_FEATURE: z.boolean(),
  CONTACT_US_PREVIEW: z.boolean(),
  FAQ_PREVIEW: z.boolean(),
  BRIDAL_CAMPAIGN_FEATURE: z.boolean(),
  BANNER_UNDERNEATH_FEATURE: z.boolean(),
  NEXT_PUBLIC_BLOG_ACCESS_ENDPOINT: z.string().regex(/[\w\-]+\.myshopify\.com/),
  NEXT_PUBLIC_BLOG_ACCESS_TOKEN: z.string().min(1),
  NEXT_PUBLIC_SUBSCRIBER_API: z.string(),
  STORE_CREDIT_TOKEN: z.string().nullish(),
  MULTICURRENCY_FEATURE: z.boolean(),
  SHOW_TOLSTOY: z.boolean(),
  NEXT_PUBLIC_TOLSTOY_APP_KEY: z.string().min(1).optional().or(z.literal('')),
  NEXT_PUBLIC_TOLSTOY_HOMEPAGE_ID: z.string().min(1).optional().or(z.literal('')),
  NEXT_PUBLIC_TOLSTOY_PDP_ID: z.string().min(1).optional().or(z.literal('')),
  TOLSTOY_FEATURE: z.boolean(),
  UK_LIVE: z.boolean().optional(),
});

const env = envSchema.parse({
  NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
  NEXT_PUBLIC_REGION: process.env.NEXT_PUBLIC_REGION,
  NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
  NEXT_PUBLIC_SEARCHSPRING_BEACON_URL: process.env.NEXT_PUBLIC_SEARCHSPRING_BEACON_URL,
  NEXT_PUBLIC_SEARCHSPRING_SITEID: process.env.NEXT_PUBLIC_SEARCHSPRING_SITEID,
  NEXT_PUBLIC_BASE_SEARCHSPRING_URL: process.env.NEXT_PUBLIC_BASE_SEARCHSPRING_URL,
  NEXT_PUBLIC_YOTPO_ACCOUNT_ID: process.env.NEXT_PUBLIC_YOTPO_ACCOUNT_ID,
  NEXT_PUBLIC_YOTPO_APP_KEY: process.env.NEXT_PUBLIC_YOTPO_APP_KEY,
  NEXT_PUBLIC_YOTPO_TMP_KEY: process.env.NEXT_PUBLIC_YOTPO_TMP_KEY,
  NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_SIZE_ID: process.env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_SIZE_ID,
  NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_HEIGHT_ID: process.env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_HEIGHT_ID,
  NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_FIT_ID: process.env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_FIT_ID,
  NEXT_PUBLIC_HELLO_MOLLY_GRAPHQL: process.env.NEXT_PUBLIC_HELLO_MOLLY_GRAPHQL,
  NEXT_PUBLIC_ZENDESK_ACCOUNT_SUBDOMAIN: process.env.NEXT_PUBLIC_ZENDESK_ACCOUNT_SUBDOMAIN,
  PRODUCT_CARD_QUICK_ADD_FEATURE: true,
  HOME_INSTAGRAM_SECTION: false,
  HOME_MIDDLE_BANNER_SECTION: false,
  SOCIAL_LOGIN_FEATURE: false,
  DOB_FEATURE: false,
  PROFILE_WISHLIST_FEATURE: process.env.NEXT_PUBLIC_NODE_ENV !== 'production',
  PROFILE_MOBILE_DROPDOWN_FEATURE: process.env.NEXT_PUBLIC_NODE_ENV !== 'production',
  PRODUCT_CARD_CAROUSEL_FEATURE: true,
  PRODUCT_REVIEWS_FEATURE: true,
  PRODUCT_REVIEWS_CREATE_FEATURE: true,
  PRODUCT_REVIEWS_VOTE_FEATURE: false,
  PRODUCT_REVIEWS_PAGINATION_FEATURE: true,
  PRODUCT_BNPL_FEATURE: true,
  COLOR_SWATCH_FEATURE: true,
  NEWSLETTER_FEATURE: true,
  ADDRESS_FINDER: false,
  ORDER_DETAILS_EDIT: false,
  ORDER_PAYMENT_DETAILS: false,
  CART_UPSELL_FEATURE: true,
  CONTACT_FORM_FEATURE: true,
  NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION: process.env.NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION,
  NEXT_PUBLIC_SHOPIFY_STORE_URL: process.env.NEXT_PUBLIC_SHOPIFY_STORE_URL,
  UNIDAYS_FEATURE: true,
  GIFT_CARDS_FEATURE: true,
  ABOUT_US_PREVIEW: true,
  ABOUT_US_SCROLL_FEATURE: true,
  WISHLIST_PREVIEW: true,
  WISHLIST_BOARD: false,
  PDP_DONT_MISS_OUT_FEATURE: false,
  SIGNUP_LOGIN_PREVIEW: true,
  RETURNS_PREVIEW: true,
  CART_DRAWER_PREVIEW: true,
  STORE_LOCATOR_FEATURE: true,
  CONTACT_US_PREVIEW: true,
  FAQ_PREVIEW: true,
  BRIDAL_CAMPAIGN_FEATURE: true,
  BANNER_UNDERNEATH_FEATURE: false,
  NEXT_PUBLIC_BLOG_ACCESS_ENDPOINT:
    process.env.NEXT_PUBLIC_REGION === 'UK' ? 'hellomollyfashion-usa.myshopify.com' : 'hellomollyfashion.myshopify.com',
  NEXT_PUBLIC_BLOG_ACCESS_TOKEN: process.env.NEXT_PUBLIC_BLOG_ACCESS_TOKEN,
  NEXT_PUBLIC_SUBSCRIBER_API: process.env.NEXT_PUBLIC_SUBSCRIBER_API,
  STORE_CREDIT_TOKEN: process.env.STORE_CREDIT_TOKEN,
  MULTICURRENCY_FEATURE: process.env.NEXT_PUBLIC_REGION === 'US',
  SHOW_TOLSTOY: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
  NEXT_PUBLIC_TOLSTOY_APP_KEY: process.env.NEXT_PUBLIC_TOLSTOY_APP_KEY,
  NEXT_PUBLIC_TOLSTOY_HOMEPAGE_ID: process.env.NEXT_PUBLIC_TOLSTOY_HOMEPAGE_ID,
  NEXT_PUBLIC_TOLSTOY_PDP_ID: process.env.NEXT_PUBLIC_TOLSTOY_PDP_ID,
  TOLSTOY_FEATURE: false,
  UK_LIVE: false,
});

export default env;
