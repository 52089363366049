import React from 'react';
import { Accordion, NextLink, Prose } from '@ui/components/core';
import FAQAccordion from '../FAQAccordion/FAQAccordion';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';

const FAQDelivery = (): React.ReactElement => (
  <FAQAccordion handle="delivery">
    {useStaticContent('FAQ.Delivery.AustralianDelivery') && (
      <Accordion.Item value="australian-delivery">
        <Accordion.Control>Australian delivery</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.AustralianDelivery" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.UKDelivery') && (
      <Accordion.Item value="uk-delivery">
        <Accordion.Control>UK delivery</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.UKDelivery" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.Customs') && (
      <Accordion.Item value="customs">
        <Accordion.Control>Customs</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.Customs" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.AusPostInstructions') && (
      <Accordion.Item value="auspost">
        <Accordion.Control>Delivery instructions for Australia Post</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.AusPostInstructions" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.InternationalDelivery') && (
      <Accordion.Item value="international-delivery">
        <Accordion.Control>International Delivery</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.InternationalDelivery" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.MissingOrder') && (
      <Accordion.Item value="missing-order">
        <Accordion.Control>Missing Order</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.MissingOrder" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.CollectionCard') && (
      <Accordion.Item value="collection-card">
        <Accordion.Control>No collection card from Australia Post</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.CollectionCard" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.CanadianOrders') && (
      <Accordion.Item value="canada-orders">
        <Accordion.Control>Orders to Canada</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.CanadianOrders" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.OvernightUSShipping') && (
      <Accordion.Item value="overnight-us-shipping">
        <Accordion.Control>Overnight shipping to US</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.OvernightUSShipping" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.SameDayDelivery') && (
      <Accordion.Item value="same-day-delivery">
        <Accordion.Control>Same-day delivery</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.SameDayDelivery" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Delivery.Tracking') && (
      <Accordion.Item value="tracking">
        <Accordion.Control>Tracking information</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Delivery.Tracking" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
  </FAQAccordion>
);

export default FAQDelivery;
