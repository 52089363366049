import { Carousel, LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import env from '@ui/env';
import { recommendationProductsToSearchProducts } from '@ui/helpers/recommendationProductsToSearchProducts';
import useBeacon from '@ui/hooks/useBeacon';
import useCartStore, { CartProduct } from '@ui/store/cartStore';
import { useQuery } from '@tanstack/react-query';
import { getCookie } from '@ui/hooks/useCookie';
import personalizedRecommendations from '@ui/axios/searchSpring/personalizedRecommendations';
import { CarouselProps, useAnimationOffsetEffect } from '@mantine/carousel';
import CartUpsellCard from './CartUpsellCard';
import cn from '@ui/utils/cn';
import CartUpsellCardSkeleton from './CartUpsellCardSkeleton';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { GetProductsPricing } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';

export type CartUpsellAddToCartItem = Omit<CartProduct, 'price'>;

type CartUpsellCarouselProps = {
  className?: string;
  classNames?: CarouselProps['classNames'];
  beaconPlacement: 'cart' | 'product-page';
};

const mapProducts = recommendationProductsToSearchProducts;

const CartUpsellCarousel = ({ className, classNames, beaconPlacement }: CartUpsellCarouselProps) => {
  const cartId = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);

  const { data: getCartQueryResults } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartId || '',
  });

  const { data: cartUpsellProducts, isLoading } = useQuery({
    queryKey: [
      'cartUpsellProducts',
      getCartQueryResults?.cart?.cartLines && getCartQueryResults?.cart?.cartLines?.length,
      cartUpdatedKey,
    ],
    queryFn: async () => {
      const result = await personalizedRecommendations({
        tags: 'cart-up-sell',
        shopper: getCookie('shopper'),
        cart: getCookie('cart'),
        lastViewed: getCookie('lastViewed'),
      });

      const products = mapProducts(result.data[0].results);

      if (env.MULTICURRENCY_FEATURE && products.length > 0) {
        const productsPricing = await GetProductsPricing({
          first: products.length,
          query: products.map((p) => `id:${p.mappings.core.uid}`).join(' OR '),
          country: getCustomerCountry(),
        });

        products.forEach((product) => {
          const productEdges = productsPricing.data?.products.edges || [];
          const productPrice = productEdges.find((edge) => edge.node.id.split('/').pop() === product.mappings.core.uid);
          if (!productPrice) return;
          product.price = productPrice.node.priceRange.maxVariantPrice.amount;
          product.currency = productPrice.node.priceRange.maxVariantPrice.currencyCode;
          product.variant_compare_at_price = productPrice.node.compareAtPriceRange.maxVariantPrice.amount;
        });
      }

      return products;
    },
  });

  const { ref, embla, setEmbla } = useBeacon({
    tag: 'cart-upsell',
    placement: beaconPlacement,
    products: (cartUpsellProducts || []).map((product) => ({
      id: product.id,
      mappings: product.mappings,
    })),
  });

  useAnimationOffsetEffect(embla as any, 500);

  if (!env.CART_UPSELL_FEATURE) {
    return null;
  }

  if (!isLoading && !cartUpsellProducts?.length) {
    return null;
  }

  return (
    <Carousel
      ref={ref}
      withControls
      loop
      align="start"
      getEmblaApi={setEmbla as any}
      nextControlIcon={<RightChevronIcon height={24} width={24} />}
      previousControlIcon={<LeftChevronIcon height={24} width={24} />}
      className={className}
      classNames={{
        ...classNames,
        controls: cn('hidden md:flex', classNames?.controls),
        control: cn('bg-transparent', classNames?.control),
        slide: cn('basis-auto w-[90%] md:w-full border-[0.6px] p-3 border-black bg-white mx-2', classNames?.slide),
      }}
    >
      {isLoading ? (
        <Carousel.Slide>
          <CartUpsellCardSkeleton />
        </Carousel.Slide>
      ) : (
        cartUpsellProducts?.map((cartUpsellProduct) => (
          <Carousel.Slide key={cartUpsellProduct.id}>
            <CartUpsellCard cartUpsellProduct={cartUpsellProduct} />
          </Carousel.Slide>
        ))
      )}
    </Carousel>
  );
};

export default CartUpsellCarousel;
