import React from 'react';
import { PlusIcon } from '@ui/components/core';
import env from '@ui/env';
import { SearchProduct } from '@ui/helpers/recommendationProductsToSearchProducts';
import useAddToCart from '@ui/hooks/useAddToCart';
import Link from 'next/link';
import { Button } from '../../buttons/Button/Button';
import cn from '@ui/utils/cn';
import AddedToCart from '../../overlays/AddedToCart/AddedToCart';
import { useModalStore } from '@ui/store.export';
import Money from '../../formatters/Money/Money';

export type CartUpsellCardProps = {
  cartUpsellProduct: SearchProduct;
};

type CartUpsellVariant = {
  id: string;
  sku: string;
  size: string;
  available: boolean;
};

export default function CartUpsellCard({ cartUpsellProduct }: CartUpsellCardProps) {
  if (env.CART_DRAWER_PREVIEW) {
    return <NewCartUpsellCard cartUpsellProduct={cartUpsellProduct} />;
  } else {
    return <OldCartUpsellCard cartUpsellProduct={cartUpsellProduct} />;
  }
}

function NewCartUpsellCard({ cartUpsellProduct }: CartUpsellCardProps) {
  const [activeVariant, setActiveVariant] = React.useState<number>();
  const [addedToCart, setAddedToCart] = React.useState(false);

  const sizeArray = JSON.parse(cartUpsellProduct.ss_sizes.replace(/&quot;/g, '"')) as Array<CartUpsellVariant>;

  const openModal = useModalStore((state) => state.openModal);

  const { addToCart, isLoading } = useAddToCart({
    onSuccess: () => {
      setAddedToCart(true);

      setTimeout(() => {
        setAddedToCart(false);
        setActiveVariant(undefined);
      }, 3000);

      openModal('cart');
    },
  });

  return (
    <div className="flex space-x-4">
      <Link className="flex shrink-0" href={`/products/${cartUpsellProduct.handle}/`}>
        <picture>
          <img
            src={cartUpsellProduct.imageUrl}
            alt={`${cartUpsellProduct.title} Cart Up Sell Slide`}
            height={117}
            width={77}
          />
        </picture>
      </Link>
      <div className="flex flex-col justify-between space-y-1">
        <div>
          <Link
            href={`/products/${cartUpsellProduct.handle}/`}
            className="font-bold text-xs tracking-[0.4px] leading-4 uppercase line-clamp-2"
          >
            {cartUpsellProduct.title}
          </Link>
          <div className="text-[10px] tracking-[0.4px] leading-3 mt-2">
            <Money value={cartUpsellProduct.price} currency={cartUpsellProduct.currency} />
          </div>
        </div>
        <div className="space-y-1">
          <div className="text-xs tracking-[0.004em] leading-4 uppercase">
            {activeVariant === undefined ? 'SIZE: SELECT SIZE' : `SIZE: ${sizeArray[activeVariant].size}`}
          </div>
          {activeVariant === undefined ? (
            <div className="flex space-x-3">
              {sizeArray.map((size, i) => (
                <Button
                  key={size.id}
                  variant="outline"
                  disabled={isLoading}
                  checked={activeVariant === i}
                  className={cn('!text-xs size-8 p-0', size.size === 'One Size' && 'w-auto px-3')}
                  onClick={() => setActiveVariant(i)}
                >
                  {size.size}
                </Button>
              ))}
            </div>
          ) : (
            <Button
              className="!text-xs h-9 w-40 px-3 space-x-3"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                if (activeVariant === undefined) return;

                const cartUpsellVariant = sizeArray[activeVariant];

                if (!cartUpsellVariant) return;

                addToCart({
                  productVariantId: `gid://shopify/ProductVariant/${cartUpsellVariant.id}`,
                  intellisuggestData: '',
                  intellisuggestSignature: '',
                });
              }}
            >
              <span>Add</span>
              <PlusIcon className="[&_path]:fill-white" width={14} height={14} />
              <AddedToCart show={addedToCart} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

function OldCartUpsellCard({ cartUpsellProduct }: CartUpsellCardProps) {
  const [activeVariant, setActiveVariant] = React.useState(0);

  const sizeArray = JSON.parse(cartUpsellProduct.ss_sizes.replace(/&quot;/g, '"')) as Array<CartUpsellVariant>;

  const { addToCart, isLoading } = useAddToCart();

  return (
    <div>
      <div className="hidden md:flex space-x-4">
        <Link className="flex shrink-0" href={`/products/${cartUpsellProduct.handle}/`}>
          <picture>
            <img
              src={cartUpsellProduct.imageUrl}
              alt={`${cartUpsellProduct.title} Cart Up Sell Slide`}
              height={99}
              width={66}
            />
          </picture>
        </Link>
        <div className="flex flex-col justify-between space-y-1">
          <div>
            <Link
              href={`/products/${cartUpsellProduct.handle}/`}
              className="font-bold text-[14px] tracking-[0.55px] leading-[17px] line-clamp-2"
            >
              {cartUpsellProduct.title}
            </Link>
            <div className="font-normal text-[12px] mt-1">
              {env.NEXT_PUBLIC_REGION}${cartUpsellProduct.price.toFixed(2)}
            </div>
          </div>
          <div className="flex space-x-3">
            {sizeArray.length > 1 &&
              sizeArray.map((size, i) => (
                <Button
                  key={size.id}
                  variant="outline"
                  disabled={isLoading}
                  checked={activeVariant === i}
                  className="!text-[9px] size-8 p-0"
                  onClick={() => setActiveVariant(i)}
                >
                  {size.size}
                </Button>
              ))}
            <Button
              className="!text-[9px] h-8 px-2"
              loading={isLoading}
              onClick={() => {
                const cartUpsellVariant = sizeArray[activeVariant];

                if (!cartUpsellVariant) return;

                addToCart({
                  productVariantId: `gid://shopify/ProductVariant/${cartUpsellVariant.id}`,
                  intellisuggestData: '',
                  intellisuggestSignature: '',
                });
              }}
            >
              Add <PlusIcon className="[&_path]:fill-white" width={14} height={14} />
            </Button>
          </div>
        </div>
      </div>
      <div className="md:hidden space-y-4">
        <div className="flex space-x-4">
          <Link className="shrink-0 flex" href={`/products/${cartUpsellProduct.handle}/`}>
            <picture>
              <img
                src={cartUpsellProduct.imageUrl}
                alt={`${cartUpsellProduct.title} Cart Up Sell Slide`}
                height={99}
                width={66}
              />
            </picture>
          </Link>
          <div className="flex-1">
            <Link
              href={`/products/${cartUpsellProduct.handle}/`}
              className="font-normal text-[14px] tracking-[0.55px] leading-[17px] line-clamp-3"
            >
              {cartUpsellProduct.title}
            </Link>
            <div className="font-normal text-[12px] mt-1">
              {env.NEXT_PUBLIC_REGION}${cartUpsellProduct.price.toFixed(2)}
            </div>
          </div>
        </div>
        <div className="flex space-x-3">
          {sizeArray.length > 1 &&
            sizeArray.map((size, i) => (
              <Button
                key={size.id}
                variant="outline"
                disabled={isLoading}
                checked={activeVariant === i}
                className="!text-[9px] size-8 p-0"
                onClick={() => setActiveVariant(i)}
              >
                {size.size}
              </Button>
            ))}
          <Button
            className="!text-[9px] h-8 px-2"
            loading={isLoading}
            onClick={() => {
              const cartUpsellVariant = sizeArray[activeVariant];

              if (!cartUpsellVariant) return;

              addToCart({
                productVariantId: `gid://shopify/ProductVariant/${cartUpsellVariant.id}`,
                intellisuggestData: '',
                intellisuggestSignature: '',
              });
            }}
          >
            Add <PlusIcon className="[&_path]:fill-white" width={14} height={14} />
          </Button>
        </div>
      </div>
    </div>
  );
}
