import React from 'react';
import { useRouter } from 'next/router';
import {
  AUFlagIcon,
  Box,
  Container,
  Divider,
  GiftCardIcon,
  Group,
  Logo,
  NextImage,
  NextLink,
  NZFlagIcon,
  Stack,
  UnstyledButton,
  USFlagIcon,
} from '../../../core';
import { SubTitle, Text } from '../../typography';
import { backgroundStyles, responsiveStyles } from '../../../styles';
import CountrySelector from '../../selectors/CountrySelector/CountrySelector';
import { FooterMenuRoutes } from '../../../../types/routing/FooterMenuRoutingTable.type';
import env from '@ui/env';

type FooterMenuProps = {
  menuHeadingLabels: Array<string>;
  menuLabels: { [key: string]: Array<string> };
};

const FooterMenu = ({ menuHeadingLabels, menuLabels }: FooterMenuProps): React.ReactElement => {
  const router = useRouter();

  const background = backgroundStyles();
  const responsive = responsiveStyles();

  const socialLinks = (
    <>
      <a href="https://tiktok.com/@hellomolly/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-tiktok.png?v=1700115989"
          alt="Hello Molly on Tiktok"
          width="224"
          height="240"
        />
      </a>
      <a href="https://instagram.com/hellomolly/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-instagram.png?v=1700115989"
          alt="Hello Molly on Instagram"
          width="241"
          height="240"
        />
      </a>
      <a href="https://www.facebook.com/hellomolly/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-facebook.png?v=1700115988"
          alt="Hello Molly on Facebook"
          width="127"
          height="240"
        />
      </a>
      <a href="https://www.pinterest.com/hellomollyfshn/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-pinterest.png?v=1700115988"
          alt="Hello Molly on Pinterest"
          width="207"
          height="253"
        />
      </a>
      <a href="https://www.youtube.com/user/HelloMollyFashion/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-youtube.png?v=1700115987"
          alt="Hello Molly on Youtube"
          width="331"
          height="240"
        />
      </a>
      <a href="https://www.linkedin.com/company/hello-molly/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-linkedin.png?v=1700115988"
          alt="Hello Molly on Linked In"
          width="254"
          height="240"
        />
      </a>
      <a href="https://twitter.com/LoveHellomolly/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-twitter.png?v=1700115987"
          alt="Hello Molly on Twitter"
          width="241"
          height="220"
        />
      </a>
      <a href="https://threads.net/hellomolly/" target="_blank" rel="noreferrer">
        <NextImage
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-threads.png?v=1700115987"
          alt="Hello Molly on Threads"
          width="211"
          height="244"
        />
      </a>
    </>
  );

  return (
    <>
      <Box className={responsive.classes.hiddenMobile} sx={{ borderBottom: '0.6px solid black' }}>
        <Box className="bg-pink-3">
          <Container py="3rem">
            <Group align="flex-start" position="apart">
              <Group align="flex-start" spacing="3rem">
                {menuHeadingLabels.map((menuHeadingLabel) => (
                  <Stack key={`${menuHeadingLabel}-footer-menu-heading`} spacing="0.875rem" w={200}>
                    <Text size="0.875rem" fw={700}>
                      {menuHeadingLabel}
                    </Text>
                    {menuLabels[menuHeadingLabel].map((menuLabel) => (
                      <Box key={`${menuLabel}-footer-menu-pagelink`}>
                        {menuLabel === 'Gift Cards' ? (
                          <Group spacing="xs" py="1rem">
                            <GiftCardIcon height={30} width={30} />
                            <NextLink
                              style={{ letterSpacing: 0 }}
                              label={menuLabel}
                              href="/pages/gift-cards/"
                              variant="underline"
                              bold
                            />
                          </Group>
                        ) : (
                          <Box>
                            <NextLink
                              style={{ letterSpacing: 0 }}
                              label={menuLabel}
                              href={FooterMenuRoutes[menuLabel.toUpperCase() as keyof typeof FooterMenuRoutes] || '#'}
                              variant="underline"
                            />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Stack>
                ))}
              </Group>
              <Stack>
                <CountrySelector gap="1rem" align="center" />
                <Group py="xl" position="center" spacing="2rem">
                  {socialLinks}
                </Group>
              </Stack>
            </Group>
          </Container>
        </Box>
        <Box bg="#FCF3F6" sx={{ borderTop: '0.6px solid black' }}>
          <Container>
            <div className="flex relative items-end justify-center pt-4 pb-2">
              <div className="flex items-end absolute left-0">
                <Logo height={35} width={100} className="mr-8" />
                <NextLink
                  label="Terms & Conditions"
                  href="/pages/terms-and-conditions/"
                  variant="underline"
                  className="tracking-[0em]"
                />
                <span className="mx-4 text-[14px]">|</span>
                <NextLink
                  label="Privacy Policy"
                  href="/pages/privacy-policy/"
                  variant="underline"
                  className="tracking-[0em]"
                />
              </div>
              <div className="text-[12px] tracking-[-0.06px]">© 2024 Hello Molly. All Rights Reserved</div>
            </div>
          </Container>
        </Box>
      </Box>
      <Box className={responsive.classes.hiddenDesktop} sx={{ borderBottom: '0.6px solid black' }}>
        <Box className={background.classes.theme}>
          <Container className="px-4 py-8">
            <div className="grid grid-cols-2 gap-9">
              {menuHeadingLabels.map((menuHeadingLabel) => (
                <div key={menuHeadingLabel}>
                  <SubTitle className="font-bold text-[14px] mb-[22px]">{menuHeadingLabel}</SubTitle>
                  {menuLabels[menuHeadingLabel]
                    .filter((menuLabel) => menuLabel !== 'Gift Cards')
                    .map((menuLabel) => (
                      <div key={menuLabel} className="mb-[18px] last-of-type:mb-0">
                        <NextLink
                          label={menuLabel}
                          href={FooterMenuRoutes[menuLabel.toUpperCase() as keyof typeof FooterMenuRoutes] || '#'}
                          className="tracking-normal text-[14px]"
                          variant="underline"
                        />
                      </div>
                    ))}
                </div>
              ))}
              <div>
                <Group spacing="0.5rem">
                  <GiftCardIcon height={30} width={30} />
                  <NextLink
                    label="Gift Cards"
                    href="/pages/gift-cards/"
                    variant="underline"
                    className="tracking-normal"
                    bold
                  />
                </Group>
              </div>
            </div>
          </Container>
        </Box>
        <Box
          sx={{
            borderTop: '0.6px solid black',
            borderBottom: '0.6px solid black',
          }}
        >
          <Box className={background.classes.theme}>
            <Container size="xl" py="xl">
              <Stack align="center" justify="center">
                <Text tt="uppercase">You&apos;re shopping in:</Text>
                <Group position="center">
                  <UnstyledButton
                    onClick={() => router.push('https://nz.hellomolly.com/')}
                    data-selected={env.NEXT_PUBLIC_REGION === 'NZ' || undefined}
                    className="opacity-30 hover:opacity-100 data-[selected]:opacity-100"
                  >
                    <Group>
                      <NZFlagIcon height={30} width={30} />
                      <Text>NZ</Text>
                    </Group>
                  </UnstyledButton>
                  <Divider orientation="vertical" color="black" />
                  <UnstyledButton
                    onClick={() => router.push('https://www.hellomolly.com.au/')}
                    data-selected={env.NEXT_PUBLIC_REGION === 'AU' || undefined}
                    className="opacity-30 hover:opacity-100 data-[selected]:opacity-100"
                  >
                    <Group>
                      <AUFlagIcon height={30} width={30} />
                      <Text>AU</Text>
                    </Group>
                  </UnstyledButton>
                  <Divider orientation="vertical" color="black" />
                  <UnstyledButton
                    onClick={() => router.push('https://www.hellomolly.com/')}
                    data-selected={env.NEXT_PUBLIC_REGION === 'US' || undefined}
                    className="opacity-30 hover:opacity-100 data-[selected]:opacity-100"
                  >
                    <Group>
                      <USFlagIcon height={30} width={30} />
                      <Text>USA</Text>
                    </Group>
                  </UnstyledButton>
                </Group>
              </Stack>
            </Container>
          </Box>
        </Box>
        <Box className={background.classes.theme}>
          <Container size="xl" py="xl">
            <Group position="center">{socialLinks}</Group>
          </Container>
        </Box>
        <Box className={background.classes.themeLight} sx={{ borderTop: '0.6px solid black' }}>
          <Container size="xl" pb="1rem">
            <Stack align="center" justify="center">
              <Logo height={45} width={100} />
              <Group mt="auto" spacing="xs">
                <NextLink label="Terms & Conditions" href="/pages/terms-and-conditions/" variant="underline" />
                <Divider orientation="vertical" color="black" />
                <NextLink label="Privacy Policy" href="/pages/privacy-policy/" variant="underline" />
              </Group>
              <Text>© 2024 Hello Molly. All Rights Reserved</Text>
            </Stack>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default FooterMenu;
