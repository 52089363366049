import React from 'react';
import { Dialog, DownChevronIcon, Menu } from '@ui/components/core';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import env from '@ui/env';
import Image from 'next/image';
import { Button } from '../../buttons/Button/Button';
import { GetLocalizations } from '@client-shopify/gql/storefront/api/queries';
import cn from '@ui/utils/cn';
import { sortBy } from 'lodash';
import { useRouter } from 'next/router';

type GetLocation = { country: string };

const LocationDialog = () => {
  const [open, setOpen] = React.useState(false);

  const { data: customerLocation } = useQuery({
    queryKey: ['customerLocation'],
    queryFn: async () => (await axios.get<GetLocation>('/api/location')).data,
  });

  React.useEffect(() => {
    if (!customerLocation) return;
    if (customerLocation.country === env.NEXT_PUBLIC_REGION) return;
    if (window.localStorage.getItem('customerCountry')) return;
    setOpen(true);
  }, [customerLocation]);

  const { data: localizations } = useQuery({
    queryKey: ['localizations'],
    queryFn: async () => (await GetLocalizations()).data,
  });

  const availableCountries = React.useMemo(() => localizations?.localization.availableCountries || [], [localizations]);
  const customerLocaleFromIp = React.useMemo(
    () => availableCountries.find((c) => c.isoCode === customerLocation?.country),
    [availableCountries, customerLocation],
  );

  const [tab, setTab] = React.useState<number>(0);
  const [locale, setLocale] = React.useState<(typeof availableCountries)[0]>();

  const router = useRouter();

  const handleSubmit = async (countryCode: string) => {
    localStorage.setItem('customerCountry', countryCode);
    router.reload();
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Title className="sr-only">Set your location</Dialog.Title>
      <Dialog.Content className="max-w-sm rounded shadow max-md:mb-0" onInteractOutside={(e) => e.preventDefault()}>
        {customerLocation && (
          <>
            {tab === 0 && (
              <div className="p-6">
                <CountryFlag className="w-12 h-auto mx-auto" countryCode={customerLocation.country} />
                <div className="text-center text-lg mt-6">
                  Your location is set to <span className="font-medium">{toCountryName(customerLocation.country)}</span>
                </div>
                <ul className="list-disc ml-4 mt-6">
                  <li>
                    Shop in {customerLocaleFromIp?.currency.isoCode} {customerLocaleFromIp?.currency.symbol}
                  </li>
                  <li>Get shipping options for {toCountryName(customerLocation.country)}</li>
                </ul>
                <Button className="w-full mt-6" onClick={() => handleSubmit(customerLocation.country)}>
                  Continue
                </Button>
                <Button
                  className="w-full mt-4"
                  variant="unstyled"
                  onClick={() => {
                    setTab(1);
                    setLocale(customerLocaleFromIp);
                  }}
                >
                  Change country/region
                </Button>
              </div>
            )}

            {tab === 1 && (
              <div className="p-6">
                <div className="text-lg">Change country/region</div>
                <ul className="mt-6">
                  <Menu
                    position="bottom-start"
                    classNames={{
                      dropdown:
                        'px-0 py-1 rounded-[4px] shadow-none border border-[#757575] bg-white h-[400px] overflow-y-scroll',
                      item: cn(
                        'px-3 py-1 flex items-center text-[13px] rounded-none',
                        'data-[hovered]:bg-white data-[hovered]:underline data-[hovered]:underline-offset-[6px]',
                        'data-[selected]:bg-white data-[selected]:underline data-[selected]:underline-offset-[6px]',
                      ),
                    }}
                  >
                    <Menu.Target>
                      <button
                        type="button"
                        className={cn(
                          'flex w-full items-center space-x-2 rounded-[4px] px-3 py-2 mr-2.5 group',
                          'border border-[#757575] bg-white',
                          'focus:outline-none',
                        )}
                      >
                        <CountryFlag className="h-4 w-auto" countryCode={locale!.isoCode} />
                        <span className="text-left flex-1">
                          {locale?.name} ({locale?.currency.isoCode} {locale?.currency.symbol})
                        </span>
                        <DownChevronIcon
                          className="group-data-[expanded]:rotate-180 transition"
                          width={16}
                          height={16}
                        />
                      </button>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {sortBy(availableCountries, 'name').map((country) => (
                        <Menu.Item key={country.isoCode} onClick={() => setLocale(country)}>
                          {country.name} ({country.currency.isoCode} {country.currency.symbol})
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </ul>
                <Button className="w-full mt-4" onClick={() => handleSubmit(locale!.name)}>
                  Continue
                </Button>
                <Button className="w-full mt-4" variant="unstyled" onClick={() => setTab(0)}>
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

const CountryFlag = ({ countryCode, className }: { className?: string; countryCode: string }) => (
  <Image
    alt=""
    className={className}
    height={480}
    src={`https://cdn.shopify.com/static/images/flags/${countryCode.toLowerCase()}.svg`}
    width={640}
  />
);

function toCountryName(countryCode: string) {
  const displayName = new Intl.DisplayNames(['en'], { type: 'region' });
  return displayName.of(countryCode);
}

export default LocationDialog;
