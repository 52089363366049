import React, { useMemo, ForwardedRef } from 'react';
import { useRouter } from 'next/router';
import { CircleCloseIcon, CompactSearchIcon } from '../../../core';
import { Transition } from '@mantine/core';
import { getCookie, setCookie } from '@ui/hooks/useCookie';
import cn from '@ui/utils/cn';
import { debounce } from 'lodash';
import { default as getAutocomplete } from '@ui/axios/searchSpring/autocomplete';
import { useMediaQuery } from '@mantine/hooks';

type SearchFormProps = {
  isLoading?: boolean;
  searchString?: string;
  setSearchString: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSearchData: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  runSearchQuery: ({
    searchQuery,
    signal,
  }: {
    searchQuery: string;
    signal: AbortSignal;
  }) => ReturnType<typeof getAutocomplete>;
  handleResults: (searchQuery: string) => void;
};

const SearchForm = React.forwardRef(function SearchForm(
  { isLoading, searchString = '', onClose, handleResults }: SearchFormProps,
  ref,
) {
  const router = useRouter();
  const [animate, setAnimate] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState(searchString);
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  useMemo(() => {
    setSearchQuery(searchString);
  }, [searchString]);

  const handleChange = useMemo(() => debounce(handleResults, 500), [handleResults]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        const recentSearches = getCookie('recentSearches')
          .split(',')
          .filter((item) => item !== '')
          .toSpliced(7)
          .toString();
        setCookie('recentSearches', searchQuery + ',' + recentSearches);

        router.push(`/search/products/${searchQuery}`);

        if (onClose) {
          onClose();
        }
      }}
    >
      <div className="border-[0.6px] border-black rounded-[6.25rem] bg-[#FAEAF0] overflow-hidden">
        <Transition
          duration={300}
          mounted={animate}
          timingFunction="ease-out"
          transition={{
            in: { transform: 'scale(1)', opacity: 1 },
            out: { transform: 'scale(0.8)', opacity: 0 },
            common: { transformOrigin: 'bottom right' },
            transitionProperty: 'transform,opacity',
          }}
        >
          {(styles) => (
            <div className="relative flex items-center flex-1" style={isDesktop ? styles : {}}>
              <div className="absolute left-[0.38rem] pointer-events-none flex items-center justify-center rounded-full bg-[#EEBFD4] w-5 h-5">
                <CompactSearchIcon className="w-[10.66px] h-[10.66px]" />
              </div>
              <input
                ref={ref as React.MutableRefObject<HTMLInputElement>}
                type="text"
                className={cn(
                  'bg-transparent w-full h-8 text-sm tracking-[0.03em] pl-9',
                  'placeholder:text-black',
                  'focus:outline-none',
                )}
                value={value}
                placeholder="Search"
                onChange={(e) => {
                  setValue(e.target.value);
                  setSearchQuery(e.target.value);
                  handleChange(e.target.value);
                }}
                onFocus={(e) => {
                  let length = e.target.value.length;
                  e.target.setSelectionRange(length, length);
                }}
                autoFocus
                data-autofocus
                data-searchform
              />
              <div className="absolute right-[0.38rem] flex gap-[0.5625rem]">
                {searchQuery && (
                  <Transition
                    duration={300}
                    mounted={animate}
                    timingFunction="ease-out"
                    transition={{
                      in: { transform: 'scale(1)', opacity: 1 },
                      out: { transform: 'scale(0.8)', opacity: 0 },
                      common: { transformOrigin: 'bottom right' },
                      transitionProperty: 'transform,opacity',
                    }}
                  >
                    {(styles) => (
                      <button
                        type="button"
                        className="underline text-xs"
                        onClick={() => {
                          setSearchQuery('');
                          handleChange('');
                          setValue('');
                        }}
                        style={styles}
                      >
                        Clear all
                      </button>
                    )}
                  </Transition>
                )}
                <Transition
                  duration={300}
                  mounted={animate}
                  timingFunction="ease-out"
                  transition={{
                    in: { transform: 'scale(1)', opacity: 1 },
                    out: { transform: 'scale(0.8)', opacity: 0 },
                    common: { transformOrigin: 'bottom right' },
                    transitionProperty: 'transform,opacity',
                  }}
                >
                  {(styles) => (
                    <button
                      type="button"
                      className={cn(
                        'flex items-center justify-center shrink-0',
                        'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-[#F3C5D7]',
                      )}
                      onClick={onClose}
                      style={styles}
                    >
                      <CircleCloseIcon className="w-[15px] h-[15px]" />
                    </button>
                  )}
                </Transition>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </form>
  );
});

SearchForm.defaultProps = {
  onClose: undefined,
};

export default SearchForm;
