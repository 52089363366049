import { StaticContentType } from '@ui/providers';
import { innerScriptFunc } from './forter';
import { emarsysInnerScriptFunc, emarsysInnerScarabScriptFunc } from './emarsys';
import {
  CategoryFilterMapping,
  CollectionFilterMapping,
  DesktopMenuLinkTree,
  MegaMenuDropdownLabels,
  MegaMenuHeaderDropdownHeadingLabels,
  MegaMenuHeadingLabels,
  MobileMenuLinkTree,
  MegaMenuRoutes,
  ParentCollectionAssociations,
} from './menuMappings';
import { GiftCardDenominations, GiftCardTemplates } from './giftcards';
import { CollectionBannerMappings } from '../collections';
import { clothingSizes, sizeConversions, shoeSizes } from './sizeGuideMappings';
import { FaqMenuMappings } from './faqMenuMappings';
import { SearchReturnsIcon, SearchDeliveryIcon, SearchContactIcon } from '@ui/components/core';

export const ukContent: StaticContentType = {
  'HTML.lang': 'en-GB',
  'Tag.TikTokTrack': `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
      var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
      ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


        ttq.load('CQ3NFD3C77UAOAV5CPV0');
        ttq.page();
      }(window, document, 'ttq');
`,
  'Tag.YotpoPixel': 'https://d18eg7dreypte5.cloudfront.net/browse-abandonment/v2/generic.js',
  'Tag.YotpoDataLayer': `window.wtba = window.wtba || []`,
  'Tag.ElevarConfig': `const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/8d14f1b9545798d871b2f32ffe50fad0ddf2e58b/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;
          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`,
  'Tag.ElevarDataLayer': `if (typeof window !== "undefined") {
            function setCookie(name, value, days) {
              const date = new Date();
              date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
              const expires = "expires=" + date.toUTCString();
              document.cookie = name + "=" + value + ";" + expires + ";path=/";
            }
            function getCookie(name) {
              const nameEQ = name + "=";
              const ca = document.cookie.split(';');
              for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
              }
              return null;
            }
            window.ElevarUserIdFn = () => {
              let userId = getCookie('user_id');
              if (!userId) {
                userId = crypto.randomUUID();
                setCookie('user_id', userId, 365); 
              }
              return userId;
            };
          }`,
  'Tag.ElevarGTMSetup': `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KDQBT7Q2');`,
  'Tag.emarsysInnerScarabScriptFunc': emarsysInnerScarabScriptFunc,
  'Tag.Emarsys.WebExtend': emarsysInnerScriptFunc,
  'Tag.GTM.Linker.Domain': 'https://checkout.hellomolly.co.uk/',
  'Forter.Script': innerScriptFunc,
  'Tag.GTM.ID': 'GTM-KDQBT7Q2',
  'Seo.Home.canonical': 'https://www.hellomolly.co.uk/',
  'Seo.Home.url': 'https://www.hellomolly.co.uk/',
  'Banner.FreeShipping': '',
  'Terms.Website': 'https://www.hellomolly.co.uk/',
  'Shipping.FreeShipping': '',
  'Shipping.ShippingStatus':
    '<p>We try our best to send out your packages on time every day! Your order will be shipped from our Los Angeles warehouse and all orders received before <b>2pm PT/California time (10pm GMT) on a business day</b> will be shipped that same day! Otherwise, your order will be shipped the next business day. <b>Please note that sometimes during high-volume or sale periods, there may be some delay in having your order shipped out.</b></p>',
  'Shipping.DeliveryTime':
    '<p>Once a package has left our warehouse we have very little control over it. Please note that delivery times listed above are only estimations. <b>Hello Molly is not responsible for any delays caused by the carrier, especially during high-volume periods.</b> Hello Molly can never guarantee a delivery date.</p><p>If you are not home to accept your package, the carrier will assess whether it is safe to leave. If not, a calling card will be left in your letter box detailing the steps for arranging re-delivery or picking up your delivery at the local post office.</p><p>If you have not received your package within 15 business days of placing the order, please reach out via our <a href="/pages/contact-us">Contact Form.</a></p>',
  'Shipping.TaxesAndDuties': '<p>The price shown at checkout is inclusive of customs/import duties and VAT.</p>',
  'Product.FreeShipping': '',
  'Product.DeliveryAndReturns':
    'Hello Molly offers FREE standard shipping to the UK on all orders over £120. Expect delivery within 9 business days. We also offer express shipping. Please check our <a href="/pages/shipping">Shipping Page</a> for more details. We accept returns within 30 days of order delivery.',
  'Home.HeroBannerUrl': '/collections/new',
  'Home.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/HM-Homepages-16thDesktop.jpg?v=1726457557',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Home.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/HM-Homepages-16thMobile_Main.jpg?v=1726457559',
    alt: 'Hello Molly Hero Banner',
    width: 1440,
    height: 1433,
  },
  'Home.FeaturedCategories': [
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AU-SHOP_MINI_DRESSES.jpg?v=1726457652',
      alt: 'Mini Dresses Link Feature Card',
      label: 'Shop Mini Dresses',
      href: '/collections/mini-dress',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AU-SHOP_EXCLUSIVES.jpg?v=1726457652',
      alt: 'Exclusives Link Feature Card',
      label: 'Shop Exclusives',
      href: '/collections/exclusive',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AU-SHOP_DRESSES.jpg?v=1726457652',
      alt: 'Dresses Link Feature Card',
      label: 'Shop Dresses',
      href: '/collections/dresses',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AU-SHOP_ACCESSORIES.jpg?v=1726457652https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AU-SHOP_ACCESSORIES.jpg?v=1726457652',
      alt: 'Accessories Link Feature Card',
      label: 'Shop Accessories',
      href: '/collections/accessories',
      height: 1227,
      width: 820,
    },
  ],
  'Home.MiddleBannerDesktop': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/homepage_strip_banner.jpg?v=1719556163',
    alt: 'vote to win a hello molly wardrobe',
    height: 847,
    width: 2883,
  },
  'Home.MiddleBannerMobile': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/homepage_mobile.jpg?v=1719556007',
    alt: 'vote to win a hello molly wardrobe',
    height: 1175,
    width: 1437,
  },
  'Home.HeroVideoDesktop': 'https://cdn.shopify.com/videos/c/o/v/3e5a6a48d1d24604a5712177d9477ad7.mp4',
  'Home.HeroVideoMobile': 'https://cdn.shopify.com/videos/c/o/v/c6e936327d7d448992843a21a499ffee.mp4',
  'Menu.DesktopMenuLinkTree': DesktopMenuLinkTree,
  'Menu.MegaMenuHeadingLabels': MegaMenuHeadingLabels,
  'Menu.MegaMenuDropdownLabels': MegaMenuDropdownLabels,
  'Menu.MegaMenuHeaderDropdownHeadingLabels': MegaMenuHeaderDropdownHeadingLabels,
  'Menu.MobileMenuLinkTree': MobileMenuLinkTree,
  'Menu.CategoryFilterMapping': CategoryFilterMapping,
  'Menu.MegaMenuRoutes': MegaMenuRoutes,
  'Campaign.EOFY.HeroBannerUrl': '/collections/sale',
  'Campaign.EOFYWeek1.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-EOFY-Sale-Homepages.jpg?v=1718323377',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek1.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Mobile-AU-EOFY-Sale-Homepages.jpg?v=1718323389',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'Campaign.EOFYWeek2.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Phase-2-Sale-Homepages.jpg?v=1718866458',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek2.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Mobile-Phase-2-Sale-Homepages.jpg?v=1718866457',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'GiftCard.Templates': GiftCardTemplates,
  'GiftCard.Denominations': GiftCardDenominations,
  'WearNowPayLater.Channels': [],
  'WearNowPayLater.Requirements': [],
  'WearNowPayLater.ExampleImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/WNPL-Example-Desktop-AU-2.jpg?v=1717478736',
    alt: '',
    width: 1856,
    height: 1293,
  },
  'WearNowPayLater.ExampleImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/WNPL-Example-Mobile-AU-2.jpg?v=1717478737',
    alt: '',
    width: 1129,
    height: 1181,
  },
  'Collection.BestSellerId': 268884377733,
  'Collection.BannerMappings': CollectionBannerMappings,
  'Collection.CollectionFilterMapping': CollectionFilterMapping,
  'Collection.ParentCollectionAssociations': ParentCollectionAssociations,
  'Yotpo.SmsBumpAccount': 'au',
  'Yotpo.SmsBumpListId': 3342628,
  'Search.TrendingLinks': [
    {
      title: 'Maxi Dress',
      href: '/search/products/maxi%20dress',
    },
    {
      title: 'Formal Dress',
      href: '/search/products/formal%20dress',
    },
    {
      title: 'Weddings',
      href: '/search/products/bridesmaid%20&%20wedding%20guest',
    },
    {
      title: 'Long Sleeve Dress',
      href: '/search/products/long%20sleeve%20dress',
    },
    {
      title: 'Black Dress',
      href: '/search/products/black%20dress',
    },
    {
      title: 'Pink Dress',
      href: '/search/products/pink%20dress',
    },
    {
      title: 'Midi Dress',
      href: '/search/products/midi%20dress',
    },
    {
      title: 'Swim',
      href: '/search/products/swim',
    },
  ],
  'Search.HelpLinks': [
    {
      title: 'Returns',
      href: '/pages/returns',
      icon: SearchReturnsIcon,
    },
    {
      title: 'Shipping',
      href: '/pages/shipping',
      icon: SearchDeliveryIcon,
    },
    {
      title: 'Contact Us',
      href: '/pages/contact-us',
      icon: SearchContactIcon,
    },
  ],
  'Cart.PaymentMethods': ['paypal', 'visa', 'mastercard', 'afterpay', 'apple-pay', 'amex'],
  'Returns.Preamble':
    '<p>Hello Molly is here to spoil you. We hope you love your order, but if you are not completely satisfied with your purchase you can make a return for a refund.<br/><br/><p>We do not process exchanges.</p>',
  'Returns.ReturnRequirements':
    '<ul><li>Merchandise must be returned within <b>30 days</b> of the order delivery date.</li><li>Merchandise must be unworn, unwashed, unstained and unperfumed.</li><li>Merchandise must have all tags, including any Hello Molly hangtags, attached.</li><li>Any products marked <b>&apos;FINAL SALE&apos;</b> cannot be returned.</li><li>All footwear returns must include the original shoebox in its original condition, without postal labels.</li><li>Handbags sold with a dust bag or other packaging must be returned with the original dust bag and packaging.</li><li>Due to hygiene concerns the following cannot be returned: <b>intimates, stockings, hair accessories, hats, earrings, personal massagers, cosmetic/beauty products including self-tanners, Undercover Style Helpers.</b></li><li>Swimwear can be returned as long as all hygiene stickers are intact.</li></ul><br/><p>Please note if you paid for shipping at the time of your original order, you will not be credited the cost of shipping when we receive your return. If your return does not adhere to the requirements it may be sent back at additional cost to the customer.</p><br/><p><b>Hello Molly offers flat rate UK returns back to our Los Angeles warehouse for only £10!</b></p>',
  'Returns.ProcessingReturns':
    '<p>We aim to process returns within 1-3 business days of receiving them in our UK partner warehouse, but high volume periods, especially around the holidays, could cause delays. We apologise for any inconvenience. If you&apos;re concerned that your return has been delivered but not processed in a timely fashion, please contact us <a href="/pages/contact-us">HERE</a></p>',
  'Returns.FaultyItems':
    '<p>The Hello Molly team thoroughly inspects goods before they are shipped out. We stand behind the quality of our products, but if you come across a manufacturing fault, please contact us <a href="/pages/contact-us">HERE</a> as soon as possible.</p>',
  'SizeGuide.SizeGuideMessage':
    '<p>Our models typically wear UK/AU Size 6-8 or US Size 2-4, X-Small, Small, or One-Size garments.</p><br/><p>On each product page, we provide the model&apos;s measurements and what size she is wearing to help guide you.</p><br/><p>You can refer to the charts when selecting sizes but please note that sizes cannot be guaranteed and might vary slightly between brands. If you need any advice about sizing, please feel free to drop us a line <a href="/pages/contact-us">HERE</a></p><br/><p>The measurements in the description of individual products are taken only across the front side of the garment, not around the circumference (e.g. chest and waist).</p>',
  'SizeGuide.ClothingSizes': clothingSizes,
  'SizeGuide.SizeConversions': sizeConversions,
  'SizeGuide.ShoeSizes': shoeSizes,
  'FAQ.MenuMappings': FaqMenuMappings,
  'FAQ.PopularQuestions.Collaboration':
    'If you have a wicked social media presence, Instagram page or are just interested in collaborating with the Hello Molly brand we&apos;d LOVE to hear from you! Please contact our Publicity Coordinator at <a href="mailto:pr@hellomolly.com">pr@hellomolly.com</a> for all PR, Marketing and collaboration enquiries.',
  'FAQ.PopularQuestions.PhysicalStore':
    'Hello Molly is exclusively online! This means we can serve customers all across the world but unfortunately we don&apos;t have a physical location where you can come and try on garments before buying. Our office and warehouses are closed to the public.',
  'FAQ.PopularQuestions.ShippingSchedule':
    '<p>We offer Express and Standard shipping to the UK! Estimated delivery for Express is 1-3 business days while standard can take up to 9 business days.</p><p>Please refer to our <a href="/pages/shipping/" target="_blank">Shipping Page</a> for full details.</p><p>Please note that these shipping times may not be accurate as a result of courier delays out of our control. All shipping times are estimates only.</p>',
  'FAQ.PopularQuestions.MissingConfirmationEmail':
    '<p>We&apos;re sorry to hear that. It&apos;s possible the email has gone to your junk folder or maybe we have an incorrect email address for you. If this happens, please contact us straight away so we can verify whether the order has been placed and resend your confirmation. You can reach us <a href="/pages/contact-us">HERE</a></p>',
  'FAQ.PopularQuestions.StoreLocation':
    'Head office is in sunny Sydney, Australia! We also have a warehouse in Los Angeles, USA.',
  'FAQ.PopularQuestions.ReturnAddress':
    '<p>Refer to our <a href="/pages/returns/" target="_blank">Returns Page</a> for full details.</p>',
  'FAQ.Delivery.UKDelivery':
    '<p>We offer Express and Standard shipping to the UK! Estimated delivery for Express is 1-3 business days while standard can take up to 9 business days.</p><p>Please refer to our <a href="/pages/shipping/" target="_blank">Shipping Page</a> for full details.</p><p>Please note that these shipping times may not be accurate as a result of courier delays out of our control. All shipping times are estimates only.</p>',
  'FAQ.Delivery.Customs':
    '<p>All orders purchased via our GBP website are shipped with customs duties and taxes factored into the final rate shown at checkout.</p><p>If you&apos;re experiencing issues with Customs in your country, please contact our Customer Care <a href="/pages/contact/" target="_blank">HERE</a>.</p>',
  'FAQ.Delivery.MissingOrder':
    '<p>If your expected delivery date has passed and you still haven&apos;t received it, please check your tracking. If the parcel is not moving or tracking hasn&apos;t updated within the estimated delivery time frame, please contact the courier company (details will be available in your tracking email). If they&apos;re unhelpful, contact us through <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> and we&apos;ll start investigating straight away!</p>',
  'FAQ.Delivery.Tracking':
    '<p>Once your order is dispatched from our warehouse, you&apos;ll receive a tracking email from the courier. Please follow their instructions to track your parcel. If you haven&apos;t received a tracking email, please check your junk mailbox before contacting us.</p>',
  'FAQ.OrderIssues.GiftVouchersCancel':
    '<p>If you wish to cancel a gift voucher, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> as quickly as possible. As long as the voucher hasn&apos;t been used, we can cancel it for you.</p>',
  'FAQ.OrderIssues.OrderCancel':
    '<p>We aim to pack and ship out your order as quickly as possible. If you need to cancel your order, contact us as quickly as possible <a href="/pages/contact-us>HERE</a>. We&apos;ll try our best to accommodate the cancellation but unfortunately we cannot guarantee that it will be possible.</p>',
  'FAQ.OrderIssues.OrderChange':
    '<p>We aim to pack and ship out your order as quickly as possible. If you need to change your order, contact us as quickly as possible <a href="/pages/contact-us>HERE</a>. We&apos;ll try our best to accommodate the change but unfortunately we cannot guarantee that it will be possible.</p>',
  'FAQ.OrderIssues.Customs':
    '<p>All orders purchased via our GBP website are shipped with customs duties and taxes factored into the final rate shown at checkout.</p><p>If you&apos;re experiencing issues with Customs in your country, please contact our Customer Care <a href="/pages/contact-us>HERE</a>.</p>',
  'FAQ.OrderIssues.IncorrectItem':
    '<p>Unfortunately, our warehouse may occasionally send out an incorrect item. If this has happened to your order, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> so we can sort this out as quickly as possible. To expedite the process, please include your order number and a photo of the item you received, as well as the tag/barcode of the item.</p>',
  'FAQ.OrderIssues.MissingItem':
    '<p>If an item is missing from your order, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> with your order number and the name of the missing item. We will resolve this issue as quickly as possible.</p>',
  'FAQ.OrderIssues.OrderVerification':
    '<p>Hello Molly takes every measure against credit card fraud. If we suspect or our fraud system flags that your order may be fraudulent, your order will be automatically cancelled. You will receive a notice of the cancellation with the option to contact us to validate the order and have future transactions approved without hassle!</p><p>We understand this can be frustrating, but it is a rare occurrence and important for the protection of our customers and brand. Thank you for understanding.</p>',
  'FAQ.OrderIssues.OutOfStock':
    '<p>Hello Molly strives to provide high-quality products and service to its customers at an affordable price, but from time to time during the order processing stage, we may discover a manufacturing fault or inventory error with some products during dispatch.</p><p>If this happens and we are unable to fulfill the order, we reserve the right to cancel the product and issue a full refund of the product price to the customer&apos;s original payment method. The customer will be notified via email as soon as possible.</p>',
  'FAQ.OrderIssues.FaultyItem':
    '<p>If you believe your item is faulty, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> straight away with your order number, the item name, and a photo of the fault. We will sort this out as quickly as possible!</p><p>Please note that loose or excess threads and irregular prints are not considered faults as these are often natural results of the manufacturing process. Please read the description of each item carefully before purchasing.</p>',
  'FAQ.OrderIssues.PriceDifferenceRefund':
    '<p>If you see that we&apos;ve reduced the price of an item you recently ordered, unfortunately, we cannot refund the difference. Our prices change according to stock and demand from customers as well as occasional promotions. We apologize for any inconvenience.</p>',
  'FAQ.OrderIssues.ScamAndFraud':
    '<p>Unfortunately, there are a number of scam/"copycat" websites illegally using our images and pretending to sell our clothes. Please be wary of where you buy from. These sites often use our photographs and copy our names/descriptions. They are scams and if you do opt to shop with them you are likely to receive a low-quality copy of our clothing, an item that is different from what you ordered, or nothing at all. While we actively work to have such websites removed, it can be a slow process.</p><p>If you are unsure if a website is affiliated with Hello Molly, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> for advice.</p>',
  'FAQ.PaymentsAndPromos.PaymentsAndPromos':
    '<p>If your card is authorised, payment will be taken straight away and you&apos;ll receive an email confirmation for your order. If your card is not authorised, your order will not go through. You may need to contact your card issuer.</p><p>Some payment methods occasionally have additional validations and are not authorised straight away. This is most common with Paypal or Klarna. In these cases, while you would have received an order confirmation, your order will not be shipped until the payment is fully authorised. If you have concerns about the payment for your order please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> straight away so we can check for you.</p>',
  'FAQ.PaymentsAndPromos.Ordering':
    '<p>Once your shopping bag is full of all the items you want to purchase, you can check out by accessing your bag in the top-right corner of the screen. You will also be given the option to &apos;View Bag&apos; in a pop-up window after you have successfully added an item to your cart.</p><p>After you click &apos;Submit Order&apos; during the checkout process and your payment is successfully received, you will get a confirmation email from Hello Molly. If you checked out with your account you can also check the status of your order by logging into your account dashboard.</p>',
  'FAQ.PaymentsAndPromos.OrderPayment':
    '<p>We accept various payment methods including Visa, MasterCard, American Express, PayPal, AfterPay and Hello Molly store credit. We take your payment security very seriously so be assured that your details are safe with us.</p><p>We do not accept payment via cheque or Cash On Delivery.</p><p>We take fraud very seriously, so all credit and debit card holders are subject to validation and authorisation by both us and your card issuer.</p><p>If you&apos;re paying with a valid credit card and experience an error, please double-check that you have entered the credit card details correctly.</p><p>If you have entered the correct details and still see the error, you may wish to consider checking out with an alternative payment method or contacting your card issuer.</p>',
  'FAQ.PaymentsAndPromos.InstallmentPayments':
    '<p>We offer various payment options that allow you to pay in interest-free installments including AfterPay, Klarna and Shop Pay. If you select one of those options, you are subject to the terms and conditions of the respective payment method. In most cases, you will be charged the first instalment at the time of purchase and the remainder will be debited overtime per your agreed schedule with the payment processor.</p><p>Payment is arranged between you and the third-party payment processor. They will provide you with the full payment schedule as you complete the purchase (late fees apply if you fail to make a payment).</p>',
  'FAQ.PaymentsAndPromos.PromoCodes':
    '<p>Occasionally we may do promotions which require you to put in the code at the shopping cart. Please note that most promotions come with exclusions which may vary but most typically promo codes cannot be applied to sale items, beauty & wellness, self love, belts, blankets, stockings, jewellery, headwear, homewares, and gift card categories. You need to enter the code exactly as it was given to you, without any spaces. If it still doesn&apos;t work, it&apos;s possible that the code is expired. If you continue to have trouble, please reach out via our <a href="/pages/contact-us">Contact Form</a>.</p>',
  'FAQ.PaymentsAndPromos.PromoCodeNewCustomers':
    '<p>Sign up to our email newsletter for a one-time use discount code for new customers. If you have any trouble receiving your code, please contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.ReturnsAndRefunds.Exchanges':
    '<p>Hello Molly does not offer exchanges at this time. Please make a return and reorder whatever item/size you prefer.</p>',
  'FAQ.ReturnsAndRefunds.Returns':
    '<p>To make a return, please follow the instructions on our <a href="/pages/returns/" target="_blank">Returns page</a></p>',
  'FAQ.ReturnsAndRefunds.Turnaround':
    '<p>Our Returns Department tries to process all returns in a timely manner, but please allow up to 7 business days for your return to be processed once delivered to our office, particularly during busy periods such as the holidays.</p><p>Note that the turnaround time for returns may fluctuate from time to time depending on the volume of returns.</p>',
  'FAQ.Technical.ChangingEmail':
    '<p>The email address provided when placing your order is the unique identifier for each individual order, so we are unable to change the email address for orders once they are submitted.</p><p>We suggest that you create an account with your preferred email address and place your orders through that account to keep track of all your new and past Hello Molly orders.</p><p>If you put down an incorrect email address you can still enquire about your order status by contacting our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.Technical.EmailNotAccepted':
    '<p>Our ordering system is able to accept all email addresses, but in the event that you have tried to order using your email address and our checkout or registration system isn&apos;t accepting it, please email our Customer Care team for assistance at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>We suggest trying an alternate email address if the first email address doesn&apos;t work or email our Customer Care Team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>Please do not deliberately misspell your email address (e.g. leaving out the com.au) to force the system to accept it as this would lead to you not receiving any emails relevant to your order.</p>',
  'FAQ.Technical.NotReceivingEmails':
    '<p>If you are not receiving emails from Hello Molly, please check your spam. If you continue to have trouble, please reach out so we can help troubleshoot the issue. You can reach our Customer Care team <a href="/pages/contact-us" target="_blank">HERE</a></p>',
  'FAQ.Technical.OrderConfirmationEmails':
    '<p>Order confirmation emails are sent to the email address that the customer provides at checkout.</p><p>If you haven&apos;t received your order confirmation email within an hour of placing your order, please check your SPAM/junk mailbox. If it isn&apos;t there, contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> before placing a new order.</p><p>Unfortunately, we cannot redirect order confirmation emails to different addresses once the order has been placed.</p>',
  'FAQ.Technical.LoginProblems':
    '<p>If you&apos;re experiencing issues logging in to your account, the first step is to make sure you are trying to sign in via the correct website. Accounts are not transferable between our AU, NZ, UK and US sites so if you created an account on one, it will not work on the other. You will have to create a new account.</p><p>If you are still having trouble, please message us <a href="/pages/contact-us" target="_blank">HERE</a></p>',
  'FAQ.Technical.Unsubscribe':
    '<p>If you wish to unsubscribe from our newsletter, click the &apos;Unsubscribe&apos; link at the bottom of every email or log into your Hello Molly account and adjust your subscription preferences there.</p>',
};
