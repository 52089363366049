import { StaticContentType } from '@ui/providers';
import { innerScriptFunc } from './forter';
import { emarsysInnerScriptFunc, emarsysInnerScarabScriptFunc } from './emarsys';
import {
  CategoryFilterMapping,
  CollectionFilterMapping,
  DesktopMenuLinkTree,
  MegaMenuDropdownLabels,
  MegaMenuHeaderDropdownHeadingLabels,
  MegaMenuHeadingLabels,
  MobileMenuLinkTree,
  MegaMenuRoutes,
  ParentCollectionAssociations,
} from './menuMappings';
import { GiftCardDenominations, GiftCardTemplates } from './giftcards';
import { CollectionBannerMappings } from '../collections';
import { SearchReturnsIcon, SearchDeliveryIcon, SearchContactIcon } from '@ui/components/core';

export const usContent: StaticContentType = {
  'HTML.lang': 'en-au',
  'Tag.Hotjar.ID': `
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5004450,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
  'Tag.TikTokTrack': `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
      var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
      ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


        ttq.load('CQ3NFD3C77UAOAV5CPV0');
        ttq.page();
      }(window, document, 'ttq');
`,
  'Tag.YotpoPixel': 'https://d18eg7dreypte5.cloudfront.net/browse-abandonment/v2/generic.js',
  'Tag.YotpoDataLayer': `window.wtba = window.wtba || []`,
  'Tag.ElevarConfig': `const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/75356db2c6add315546bf0be3f3d848ccb31461b/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;

          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`,
  'Tag.ElevarDataLayer': `if (typeof window !== "undefined") {
            function setCookie(name, value, days) {
              const date = new Date();
              date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
              const expires = "expires=" + date.toUTCString();
              document.cookie = name + "=" + value + ";" + expires + ";path=/";
            }
            function getCookie(name) {
              const nameEQ = name + "=";
              const ca = document.cookie.split(';');
              for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
              }
              return null;
            }
            window.ElevarUserIdFn = () => {
              let userId = getCookie('user_id');
              if (!userId) {
                userId = crypto.randomUUID();
                setCookie('user_id', userId, 365); 
              }
              return userId;
            };
          }`,
  'Tag.ElevarGTMSetup': `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KW2CBSMQ');`,
  'Tag.emarsysInnerScarabScriptFunc': emarsysInnerScarabScriptFunc,
  'Tag.Emarsys.WebExtend': emarsysInnerScriptFunc,
  'Tag.GTM.Linker.Domain': 'https://checkout.hellomolly.com/',
  'Forter.Script': innerScriptFunc,
  'Tag.GTM.ID': 'GTM-KW2CBSMQ',
  'Seo.Home.canonical': 'https://www.hellomolly.com.au/',
  'Seo.Home.url': 'https://www.hellomolly.com.au/',
  'Banner.FreeShipping': '<b>FREE SHIPPING</b> ON ORDERS OVER AU$100',
  'Terms.Website': 'https://www.hellomolly.com.au/',
  'Shipping.FreeShipping': 'Free express shipping on Australian orders over <b>AU$100</b>',
  'Shipping.ShippingStatus':
    '<p>We try our best to send out your packages on time every day! That means all Australian orders received before <b>4pm local Sydney time on a business day</b> will be shipped out from our Sydney warehouse that same day (orders for destinations outside of Australia must be received before 2pm for same day dispatch)!</p>',
  'Shipping.DeliveryTime':
    '<p>Once a package has left our warehouse we have very little control over it. Please note that delivery times listed above are only estimations. <b>Hello Molly is not responsible for any delays caused by the carrier, especially during high-volume periods.</b> Hello Molly can never guarantee a delivery date.</p><p>If you are not home to accept your package, the carrier will assess whether it is safe to leave. If not, a calling card will be left in your letter box detailing the steps for arranging re-delivery or picking up your delivery at the local post office.</p><p>If you have not received your package within 7 business days (Australia or USA) or 30 business days (all other countries) of placing the order, email <a href="mailto:info@hellomolly.com"><b>info@hellomolly.com</b></a> with your Full Name and Order Number and we&apos;ll look into the matter immediately!</p>',
  'Shipping.TaxesAndDuties': '',
  'Product.FreeShipping': '<b>FREE</b> shipping on Australian orders over AU$100',
  'Product.DeliveryAndReturns':
    'Hello Molly offers FREE standard shipping on Australian orders over AU$100. We accept returns for store credit within 30 days of order delivery for all items not marked FINAL SALE.',
  'Home.HeroBannerUrl': '/collections/new',
  'Home.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AU-HM-Homepages-1st.jpg?v=1727659843',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Home.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AU-mobile-HM-Homepages-1st.jpg?v=1727659843',
    alt: 'Hello Molly Hero Banner',
    width: 1440,
    height: 1433,
  },
  'Home.HeroBannerVideoDesktop': {
    src: 'https://cdn.shopify.com/videos/c/o/v/2ca836af823a49bbb92cdeac5110e7d3.mp4',
    poster: {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/THUMBNAIL_3x4_3ee58c31-6a89-4496-a46d-066260d02c24.jpg?v=1727662479',
      alt: 'Video panning from a road to a billboard, showing HELLOMOLLY with two models on a green and pink background. The model wearing a black mini dress blows a bubble and the model wearing a pink maxi dress takes the crown off her head and throws it to the left. A CGI version of the bubble glows and captures the crown as it floats close to the camera.',
      width: 611,
      height: 814,
    },
  },
  'Home.HeroBannerVideoMobile': {
    src: 'https://cdn.shopify.com/videos/c/o/v/f87bb70380ca4cc0bc68e4cdc3b23d6f.mp4',
    poster: {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/THUMBNAIL_1x1_af81d690-35ca-4c14-b150-1355d9a07159.png?v=1727662480',
      alt: 'Video panning from a road to a billboard, showing HELLOMOLLY with two models on a green and pink background. The model wearing a black mini dress blows a bubble and the model wearing a pink maxi dress takes the crown off her head and throws it to the left. A CGI version of the bubble glows and captures the crown as it floats close to the camera.',
      width: 811,
      height: 811,
    },
  },
  'Home.FeaturedCategories': [
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SHOP_DRESSES.jpg?v=1727076597',
      alt: 'Dresses Link Feature Card',
      label: 'Shop Dresses',
      href: '/collections/dresses',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SHOP_PRINT.jpg?v=1727076598',
      alt: 'Printed Dresses Link Feature Card',
      label: 'Shop Printed Dresses',
      href: '/collections/printed-dresses',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SHOP_TOPS.jpg?v=1727076597',
      alt: 'Tops Link Feature Card',
      label: 'Shop Tops',
      href: '/collections/tops',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/SHOP_SHOES.jpg?v=1727076597',
      alt: 'Shoes Link Feature Card',
      label: 'Shop Shoes',
      href: '/collections/shoes',
      height: 1227,
      width: 820,
    },
  ],
  'Home.MiddleBannerDesktop': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/homepage_strip_banner.jpg?v=1719556163',
    alt: 'vote to win a hello molly wardrobe',
    height: 847,
    width: 2883,
  },
  'Home.MiddleBannerMobile': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/homepage_mobile.jpg?v=1719556007',
    alt: 'vote to win a hello molly wardrobe',
    height: 1175,
    width: 1437,
  },
  'Home.HeroVideoDesktop': 'https://cdn.shopify.com/videos/c/o/v/3e5a6a48d1d24604a5712177d9477ad7.mp4',
  'Home.HeroVideoMobile': 'https://cdn.shopify.com/videos/c/o/v/c6e936327d7d448992843a21a499ffee.mp4',
  'Menu.DesktopMenuLinkTree': DesktopMenuLinkTree,
  'Menu.MegaMenuHeadingLabels': MegaMenuHeadingLabels,
  'Menu.MegaMenuDropdownLabels': MegaMenuDropdownLabels,
  'Menu.MegaMenuHeaderDropdownHeadingLabels': MegaMenuHeaderDropdownHeadingLabels,
  'Menu.MobileMenuLinkTree': MobileMenuLinkTree,
  'Menu.CategoryFilterMapping': CategoryFilterMapping,
  'Menu.MegaMenuRoutes': MegaMenuRoutes,
  'Campaign.EOFY.HeroBannerUrl': '/collections/sale',
  'Campaign.EOFYWeek1.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-EOFY-Sale-Homepages.jpg?v=1718323377',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek1.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Mobile-AU-EOFY-Sale-Homepages.jpg?v=1718323389',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'Campaign.EOFYWeek2.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Phase-2-Sale-Homepages.jpg?v=1718866458',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek2.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Mobile-Phase-2-Sale-Homepages.jpg?v=1718866457',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'GiftCard.Templates': GiftCardTemplates,
  'GiftCard.Denominations': GiftCardDenominations,
  'WearNowPayLater.Channels': ['afterpay', 'klarna'],
  'WearNowPayLater.Requirements': [
    'To be 18+ years old',
    'A valid Australian credit or debit card',
    'To live in Australia',
  ],
  'WearNowPayLater.ExampleImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/WNPL-Example-Desktop-AU-2.jpg?v=1717478736',
    alt: '',
    width: 1856,
    height: 1293,
  },
  'WearNowPayLater.ExampleImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/WNPL-Example-Mobile-AU-2.jpg?v=1717478737',
    alt: '',
    width: 1129,
    height: 1181,
  },
  'Collection.BestSellerId': 268884377733,
  'Collection.BannerMappings': CollectionBannerMappings,
  'Collection.CollectionFilterMapping': CollectionFilterMapping,
  'Collection.ParentCollectionAssociations': ParentCollectionAssociations,
  'Yotpo.SmsBumpAccount': 'au',
  'Yotpo.SmsBumpListId': 3342628,
  'Search.TrendingLinks': [
    {
      title: 'Maxi Dress',
      href: '/search/products/maxi%20dress',
    },
    {
      title: 'Formal Dress',
      href: '/search/products/formal%20dress',
    },
    {
      title: 'Weddings',
      href: '/search/products/bridesmaid%20&%20wedding%20guest',
    },
    {
      title: 'Long Sleeve Dress',
      href: '/search/products/long%20sleeve%20dress',
    },
    {
      title: 'Black Dress',
      href: '/search/products/black%20dress',
    },
    {
      title: 'Pink Dress',
      href: '/search/products/pink%20dress',
    },
    {
      title: 'Midi Dress',
      href: '/search/products/midi%20dress',
    },
    {
      title: 'Swim',
      href: '/search/products/swim',
    },
  ],
  'Search.HelpLinks': [
    {
      title: 'Returns',
      href: '/pages/returns',
      icon: SearchReturnsIcon,
    },
    {
      title: 'Shipping',
      href: '/pages/shipping',
      icon: SearchDeliveryIcon,
    },
    {
      title: 'Contact Us',
      href: '/pages/contact-us',
      icon: SearchContactIcon,
    },
  ],
  'Cart.PaymentMethods': ['paypal', 'visa', 'mastercard', 'afterpay', 'apple-pay', 'amex'],
  'Returns.Preamble': '',
  'Returns.ReturnRequirements': '',
  'Returns.ProcessingReturns': '',
  'Returns.FaultyItems': '',
  'SizeGuide.SizeGuideMessage':
    '<p>Our models typically wear UK/AU Size 6-8 or US Size 2-4, X-Small, Small, or One-Size garments.</p><br/><p>On each product page, we provide the model&apos;s measurements and what size she is wearing to help guide you.</p><br/><p>You can refer to the charts when selecting sizes but please note that sizes cannot be guaranteed and might vary slightly between brands. If you need any advice about sizing, please feel free to drop us a line <a href="/pages/contact-us">HERE</a></p><br/><p>The measurements in the description of individual products are taken only across the front side of the garment, not around the circumference (e.g. chest and waist).</p>',
  'SizeGuide.ClothingSizes': [],
  'SizeGuide.SizeConversions': [],
  'SizeGuide.ShoeSizes': [],
  'FAQ.MenuMappings': [],
};
