import { StaticContentType } from '@ui/providers';
import { innerScriptFunc } from './forter';
import {
  CategoryFilterMapping,
  CollectionFilterMapping,
  DesktopMenuLinkTree,
  MegaMenuDropdownLabels,
  MegaMenuHeaderDropdownHeadingLabels,
  MegaMenuHeadingLabels,
  MobileMenuLinkTree,
  MegaMenuRoutes,
  ParentCollectionAssociations,
} from './menuMappings';
import { GiftCardDenominations, GiftCardTemplates } from './giftcards';
import { CollectionBannerMappings } from '../collections';
import { clothingSizes, sizeConversions, shoeSizes } from './sizeGuideMappings';
import { SearchReturnsIcon, SearchDeliveryIcon, SearchContactIcon } from '@ui/components/core';
import { FaqMenuMappings } from './faqMenuMappings';

export const nzContent: StaticContentType = {
  'HTML.lang': 'en-nz',
  'Tag.ElevarDataLayer': `if (typeof window !== "undefined") {
                      function setCookie(name, value, days) {
                        const date = new Date();
                        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                        const expires = "expires=" + date.toUTCString();
                        document.cookie = name + "=" + value + ";" + expires + ";path=/";
                      }
                      function getCookie(name) {
                        const nameEQ = name + "=";
                        const ca = document.cookie.split(';');
                        for (let i = 0; i < ca.length; i++) {
                          let c = ca[i];
                          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                        }
                        return null;
                      }
                      window.ElevarUserIdFn = () => {
                        let userId = getCookie('user_id');
                        if (!userId) {
                          userId = crypto.randomUUID();
                          setCookie('user_id', userId, 365); 
                        }
                        return userId;
                      };
                    }`,
  'Tag.ElevarGTMSetup': `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-MB4KRWXF');`,
  'Tag.ElevarConfig': `const settings = {};
                const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/24c2a44aa362a2406ae32716ac3fbbeae6abc70a/config.js")).default;
                const scriptUrl = settings.proxyPath
                  ? settings.proxyPath + config.script_src_custom_pages_proxied
                  : config.script_src_custom_pages;
              
                if (scriptUrl) {
                  const { handler } = await import(scriptUrl);
                  await handler(config, settings);
                }`,
  'Forter.Script': innerScriptFunc,
  'Tag.GTM.Linker.Domain': 'https://checkout.hellomolly.co.nz/',
  'Tag.GTM.ID': 'GTM-5PXXVT79',
  'Seo.Home.canonical': 'https://hellomolly.co.nz/',
  'Seo.Home.url': 'https://hellomolly.co.nz/',
  'Banner.FreeShipping': '<b>FREE SHIPPING</b> ON ORDERS OVER NZ$150',
  'Terms.Website': 'hellomolly.co.nz',
  'Shipping.FreeShipping': 'Free shipping on New Zealand orders over <b>NZ$150</b>',
  'Shipping.ShippingStatus':
    '<p>Orders are shipped from our Sydney-based warehouse and we aim to do so on-time every day! That means all orders received before 4pm NZT on a business day will be shipped out that same day! Otherwise, your order will be shipped the next business day. Please note that sometimes during high-volume or sale periods, there may be some delay in having your order shipped out.</p>',
  'Shipping.DeliveryTime':
    '<p>Once a package has left our warehouse we have very little control over it. Please note that delivery times listed above are only estimations. <b>Hello Molly is not responsible for any delays caused by the carrier, especially during high-volume periods.</b> Hello Molly can never guarantee a delivery date.</p><p>If you are not home to accept your package, the carrier will assess whether it is safe to leave. If not, a calling card will be left in your letter box detailing the steps for arranging re-delivery or picking up your delivery at the local post office.</p><p>If you have not received your package within 7 business days (NZ or AU) or 25 business days (all other countries) of placing the order, email <a href="mailto:info@hellomolly.com"><b>info@hellomolly.com</b></a> with your Full Name and Order Number and we&apos;ll look into the matter immediately!</p>',
  'Shipping.TaxesAndDuties':
    '<p>Please note taxes and duties may be charged for non-NZ orders.</p><p>All applicable customs and import duties and fees, taxes and any other charges are the responsibility of the customer, even if you refuse the shipment upon delivery. If your order does require these additional charges, they must be paid by you in order for your package to clear customs.</p><p>Customs authorities require by law that we state the value of your order directly on the package. Customs agents have the right to release or deny release of your package and, in rare cases, they may also delay or reject delivery.</p><p>If you refuse to pay any additional fees, your package will be sent back to us and you will be refunded the cost of your order minus any extra shipping charges incurred by us from the carrier.</p>',
  'Product.FreeShipping': '<b>FREE</b> shipping on New Zealand orders over NZ$150',
  'Product.DeliveryAndReturns':
    'Hello Molly offers FREE standard shipping on New Zealand orders over NZ$150. We accept returns for store credit within 30 days of order delivery for all items not marked FINAL SALE.',
  'Home.HeroBannerUrl': '/collections/new',
  'Home.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Desktop_3.jpg?v=1728342813',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Home.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Mobile_Main_4.jpg?v=1728342813',
    alt: 'Hello Molly Hero Banner',
    width: 1440,
    height: 1433,
  },
  // 'Home.HeroBannerVideoDesktop': {
  //   src: 'https://cdn.shopify.com/videos/c/o/v/2ca836af823a49bbb92cdeac5110e7d3.mp4',
  //   poster: {
  //     src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/THUMBNAIL_3x4_3ee58c31-6a89-4496-a46d-066260d02c24.jpg?v=1727662479',
  //     alt: 'Video panning from a road to a billboard, showing HELLOMOLLY with two models on a green and pink background. The model wearing a black mini dress blows a bubble and the model wearing a pink maxi dress takes the crown off her head and throws it to the left. A CGI version of the bubble glows and captures the crown as it floats close to the camera.',
  //     width: 611,
  //     height: 814,
  //   },
  // },
  // 'Home.HeroBannerVideoMobile': {
  //   src: 'https://cdn.shopify.com/videos/c/o/v/f87bb70380ca4cc0bc68e4cdc3b23d6f.mp4',
  //   poster: {
  //     src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/THUMBNAIL_1x1_af81d690-35ca-4c14-b150-1355d9a07159.png?v=1727662480',
  //     alt: 'Video panning from a road to a billboard, showing HELLOMOLLY with two models on a green and pink background. The model wearing a black mini dress blows a bubble and the model wearing a pink maxi dress takes the crown off her head and throws it to the left. A CGI version of the bubble glows and captures the crown as it floats close to the camera.',
  //     width: 811,
  //     height: 811,
  //   },
  // },
  'Home.FeaturedCategories': [
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Category_1-SHOP_MINI_DRESSES.jpg?v=1728343511',
      alt: 'Mini Dresses Link Feature Card',
      label: 'Shop Mini Dresses',
      href: '/collections/mini-dresses',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Category_2-SHOP_PRINTS.jpg?v=1728343511',
      alt: 'Printed Dresses Link Feature Card',
      label: 'Shop Printed Dresses',
      href: '/collections/printed-dresses',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Category_3-SHOP_FORMAL_DRESSES.jpg?v=1728343511',
      alt: 'Formal Dresses Link Feature Card',
      label: 'Shop Formal Dresses',
      href: '/collections/formal-dresses',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Category_4-SHOP_ACCESSORIES.jpg?v=1728343510',
      alt: 'Accessories Link Feature Card',
      label: 'Shop Accessories',
      href: '/collections/accessories',
      height: 1227,
      width: 820,
    },
  ],
  'Home.MiddleBannerDesktop': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/homepage_strip_banner.jpg?v=1719556163',
    alt: 'vote to win a hello molly wardrobe',
    height: 847,
    width: 2883,
  },
  'Home.MiddleBannerMobile': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/homepage_mobile.jpg?v=1719556007',
    alt: 'vote to win a hello molly wardrobe',
    height: 1175,
    width: 1437,
  },
  'Home.HeroVideoDesktop': 'https://cdn.shopify.com/videos/c/o/v/3e5a6a48d1d24604a5712177d9477ad7.mp4',
  'Home.HeroVideoMobile': 'https://cdn.shopify.com/videos/c/o/v/c6e936327d7d448992843a21a499ffee.mp4',
  'Menu.DesktopMenuLinkTree': DesktopMenuLinkTree,
  'Menu.MegaMenuHeadingLabels': MegaMenuHeadingLabels,
  'Menu.MegaMenuDropdownLabels': MegaMenuDropdownLabels,
  'Menu.MegaMenuHeaderDropdownHeadingLabels': MegaMenuHeaderDropdownHeadingLabels,
  'Menu.MobileMenuLinkTree': MobileMenuLinkTree,
  'Menu.CategoryFilterMapping': CategoryFilterMapping,
  'Menu.MegaMenuRoutes': MegaMenuRoutes,
  'Campaign.EOFY.HeroBannerUrl': '/collections/sale',
  'Campaign.EOFYWeek1.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-EOFY-Sale-Homepages.jpg?v=1718323377',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek1.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Mobile-AU-EOFY-Sale-Homepages.jpg?v=1718323389',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'Campaign.EOFYWeek2.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Phase-2-Sale-Homepages.jpg?v=1718866458',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek2.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Mobile-Phase-2-Sale-Homepages.jpg?v=1718866457',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'GiftCard.Templates': GiftCardTemplates,
  'GiftCard.Denominations': GiftCardDenominations,
  'WearNowPayLater.Channels': ['afterpay'],
  'WearNowPayLater.Requirements': [
    'To be 18+ years old',
    'A valid New Zealand credit or debit card',
    'To live in New Zealand',
  ],
  'WearNowPayLater.ExampleImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/WNPL-Example-Desktop-NZ-2.jpg?v=1717478736',
    alt: '',
    width: 1856,
    height: 1293,
  },
  'WearNowPayLater.ExampleImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/WNPL-Example-Mobile-NZ-2.jpg?v=1717478737',
    alt: '',
    width: 1129,
    height: 1181,
  },
  'Collection.BestSellerId': 467614040370,
  'Collection.BannerMappings': CollectionBannerMappings,
  'Collection.CollectionFilterMapping': CollectionFilterMapping,
  'Collection.ParentCollectionAssociations': ParentCollectionAssociations,
  'Yotpo.SmsBumpAccount': 'au', // NZ uses the same Yotpo account for AU
  'Yotpo.SmsBumpListId': 3342628,
  'Search.TrendingLinks': [
    {
      title: 'Maxi Dress',
      href: '/search/products/maxi%20dress',
    },
    {
      title: 'Formal Dress',
      href: '/search/products/formal%20dress',
    },
    {
      title: 'Weddings',
      href: '/search/products/bridesmaid%20&%20wedding%20guest',
    },
    {
      title: 'Mini Dress',
      href: '/search/products/mini%20dress',
    },
    {
      title: 'Midi Dress',
      href: '/search/products/midi%20dress',
    },
    {
      title: 'Long Sleeve Dress',
      href: '/search/products/long%20sleeve%20dress',
    },
    {
      title: 'Black Dress',
      href: '/search/products/black%20dress',
    },
    {
      title: 'Floral Dress',
      href: '/search/products/floral%20dress',
    },
  ],
  'Search.HelpLinks': [
    {
      title: 'Returns',
      href: '/pages/returns',
      icon: SearchReturnsIcon,
    },
    {
      title: 'Shipping',
      href: '/pages/shipping',
      icon: SearchDeliveryIcon,
    },
    {
      title: 'Contact Us',
      href: '/pages/contact-us',
      icon: SearchContactIcon,
    },
  ],
  'Cart.PaymentMethods': ['paypal', 'visa', 'mastercard', 'afterpay', 'apple-pay', 'amex'],
  'Returns.Preamble':
    '<p>Hello Molly is here to spoil you. We hope you love your order, but if you are not completely satisfied with your purchase we accept returns for store credit only. We do not accept exchanges.<br/><p>If you would like to order a different size, return your merchandise as soon as possible and we will issue you store credit if the items are deemed eligible according to the return requirements laid out below. Store credit can be used to order a different size or any of our other products.</p>',
  'Returns.ReturnRequirements':
    '<ul><li>Merchandise must be returned within <b>30 days</b> of the order delivery date.</li><li>Merchandise must be unworn, unwashed, unstained and unperfumed.</li><li>Merchandise must have all tags, including any Hello Molly hangtags, attached.</li><li>Any products marked <b>&apos;FINAL SALE&apos;</b> cannot be returned.</li><li>All footwear returns must include the original shoebox in its original condition, without postal labels.</li><li>Handbags sold with a dust bag or other packaging must be returned with the original dust bag and packaging.</li><li>Due to hygiene concerns the following cannot be returned: <b>intimates, stockings, hair accessories, hats, earrings, personal massagers, cosmetic/beauty products including self-tanners, Undercover Style Helpers.</b></li></ul><br/><p>Please note if you paid for shipping at the time of your original order, you will not be credited the cost of shipping when we receive your return. If your return does not adhere to the requirements it may be sent back at additional cost to the customer.</p>',
  'Returns.Afterpay':
    '<p>If you wish to return something you purchased using AfterPay, the process is the same. Unless the item is faulty, you will be issued store credit for the full amount of the unwanted item. You will continue to be charged by AfterPay until you have paid all your instalments.</p>',
  'Returns.ProcessingReturns':
    '<p>We aim to process returns within 7 business days of receiving them in our Sydney warehouse, but high volume periods, especially around the holidays, could cause delays. We apologise for any inconvenience. If you&apos;re concerned that your return has been delivered but not processed in a timely fashion, please contact us <a href="/pages/contact-us">HERE</a></p>',
  'Returns.StoreCredit':
    '<ul><li>Store credit can only be used at Hello Molly&apos;s website</li><li>Store credit cannot be transferred to another person or account.</li><li>Please treat store credit like cash.</li><li>Any purchase amounts that exceed the value of the store credit will require an additional method of payment for the remaining balance due.</li><li>Store credit is valid for 12 months after the date of issue.</li><li>Store credit is provided in the currency in which you paid.</li><li>Your use of store credit constitutes your agreement to our Terms & Conditions.</li><li>You can view your store credit balance by logging into your Hello Molly account.</li></ul>',
  'Returns.FaultyItems':
    '<p>The Hello Molly team thoroughly inspects goods before they are shipped out. We stand behind the quality of our products, but if you come across a manufacturing fault, please contact us <a href="/pages/contact-us">HERE</a> as soon as possible. Refunds cannot be guaranteed without prior photographic evidence of the fault.</p>',
  'SizeGuide.SizeGuideMessage':
    '<p>Our models typically wear Size 6-8, Small, or One-Size garments.</p><br/><p>You can refer to the charts when selecting sizes but please note that sizes cannot be guaranteed and might vary slightly between brands. If you need any advice about sizing, please feel free to drop us a line at: <strong><u>info@hellomolly.com</u></strong></p>',
  'SizeGuide.ClothingSizes': clothingSizes,
  'SizeGuide.SizeConversions': sizeConversions,
  'SizeGuide.ShoeSizes': shoeSizes,
  'FAQ.MenuMappings': FaqMenuMappings,
  'FAQ.PopularQuestions.Collaboration':
    'If you have a wicked social media presence, Instagram page or are just interested in collaborating with the Hello Molly brand we&apos;d LOVE to hear from you! Please contact our Publicity Coordinator at <a href="mailto:pr@hellomolly.com">pr@hellomolly.com</a> for all PR, Marketing and collaboration enquiries.',
  'FAQ.PopularQuestions.PhysicalStore':
    'Hello Molly is exclusively online! This means we can serve customers all across the world but unfortunately we don&apos;t have a physical location where you can come and try on garments before buying. Our office and warehouses are closed to the public.',
  'FAQ.PopularQuestions.StoreCredit':
    'Store credit is issued to customer accounts. If your Hello Molly account has credit on it, make sure you are logged in when you check out. On the checkout page you will have the option to apply your credit to the order! Please ensure you do not use an express checkout method (such as Shop Pay or Afterpay) however as it will bypass the store credit option. If you make a return and don&apos;t have an account with us, we will send you an account activation email to the email address associated with your original order. Also please note, accounts are not transferable between our Australian and US sites.',
  'FAQ.PopularQuestions.ShippingSchedule':
    '<p>We aim to ship all orders received before 2pm local Sydney time on a business day that same day. Otherwise they&apos;re sent out the next business day. We ship all AU orders express so if you are within an Australian Metro Area (check <a href="/pages/shipping/" target="_blank">here</a>) that means next business day delivery!</p><p>Please refer to our <a href="/pages/shipping/" target="_blank">Shipping Page</a> for full details and transit times to other destinations.</p><p>Please note that these shipping times may not be accurate as a result of courier delays out of our control. All shipping times are estimates only.</p>',
  'FAQ.PopularQuestions.MissingConfirmationEmail':
    'We&apos;re sorry to hear that. It&apos;s possible the email has gone to your junk folder or maybe we have an incorrect email address for you. If this happens, please contact us straight away so we can verify whether the order has been placed and resend your confirmation. You can reach us during business hours on +61 2 9017 0300 or via email on <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.',
  'FAQ.PopularQuestions.BusinessHours':
    'We&apos;re in the office from 9.30am-5pm local Sydney time Monday-Friday (excluding public holidays). You can reach us on +61 2 9017 0300.',
  'FAQ.PopularQuestions.StoreLocation': 'Head office is in sunny Sydney, Australia!',
  'FAQ.PopularQuestions.ReturnAddress':
    '<p>Please send your returns to:</p><p>Hello Molly <br />PO Box 286 <br />Strawberry Hills <br />NSW 2012</p><p>Refer to our <a href="/pages/returns/" target="_blank">Returns Page</a> for full details and information on international returns.</p>',
  'FAQ.Delivery.AustralianDelivery':
    '<p>We aim to ship all orders received before 2pm local Sydney time on a business day that same day. Otherwise, they&apos;re sent out the next business day. We ship all AU orders express so if you are within an Australian Metro Area (check <a href="/pages/shipping/" target="_blank">here</a>) that means next business day delivery!</p><p>Please refer to our <a href="/pages/shipping/" target="_blank">Shipping Page</a> for full details and transit times to other destinations.</p>',
  'FAQ.Delivery.Customs':
    '<p>If you&apos;re experiencing issues with Customs in your country, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> so that we can help you receive your order as quickly as possible.</p><p>Please note taxes and duties may be charged for international duties. All applicable customs and import duties, fees, taxes, and any other charges are the responsibility of the customer, even if you refuse the shipment upon delivery.</p><p>Customs authorities require by law that we state the value of your order directly on the package. Customs agents have the right to release or deny release of your package and, in rare cases, may also delay delivery.</p>',
  'FAQ.Delivery.AusPostInstructions':
    '<p>Orders shipped from Hello Molly within Australia by default are sent with authority to leave.</p><p>Australia Post will capture a signature wherever possible however if you are not home to accept the package, and the carrier deems there is a safe place to leave the parcel, they will do so.</p><p>For more information, see the <a href="https://auspost.com.au/mypost/how-to/deliveries.html" target="_blank" rel="noreferrer">Safe Drop</a> page. Please note that &apos;Safe Drop&apos; is a service provided by Australia Post and not Hello Molly. Customers selecting this option do so at their own risk.</p>',
  'FAQ.Delivery.InternationalDelivery':
    '<p>We aim to ship all orders received before 2pm Pacific Time on a business day that same day. Otherwise, they&apos;re sent out the next business day. We use various international couriers but primarily orders are shipped via USPS or DHL Ecommerce and delivered by your local postal carrier. The average international delivery timeframe currently is up to 2 weeks.</p><p>Please refer to our <a href="/pages/shipping" target="_blank">Shipping Page</a> for full details.</p>',
  'FAQ.Delivery.MissingOrder':
    '<p>If your expected delivery date has passed and you still haven&apos;t received it, please check your tracking. If the parcel is not moving or tracking hasn&apos;t updated within the estimated delivery time frame, please contact the courier company (details will be available in your tracking email). If they&apos;re unhelpful, contact us through <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> and we&apos;ll start investigating straight away!</p>',
  'FAQ.Delivery.CollectionCard':
    '<p>A collection card for your package will be left by Australia Post if there is an attempted delivery but no one is at the shipping address to sign for the package or it is not safe to leave unattended.</p><p>Australia Post holds undelivered packages for collection for a limited period of time. We urge all customers to collect their package at the earliest possible opportunity to avoid the package being returned to Hello Molly.</p><p>If you can&apos;t find a collection card in your letterbox, you can collect your package from the designated post office with a valid tracking number and ID.</p>',
  'FAQ.Delivery.CanadianOrders':
    '<p>From our Australian warehouse, we exclusively use Australia Post (Express Courier International - EMS) for Canadian orders. These orders will be handed over to and delivered by Canada Post. For more shipping options for Canada-bound orders, please switch to our USD website.</p>',
  'FAQ.Delivery.OvernightUSShipping':
    '<p>Hello Molly doesn&apos;t offer an overnight shipping method to the US from our Australian warehouse. For faster delivery on USA-bound orders, please ensure you are shopping on our US website. For more information please see our <a href="/pages/shipping" target="_blank">Shipping Policy</a>.</p>',
  'FAQ.Delivery.SameDayDelivery':
    '<p>If you order before 12pm on a business day, you may have the option of having your order delivered between 6-10pm that evening. For more information and a list of all eligible suburbs and postcodes, please visit <a href="/pages/shipping" target="_blank">here</a>.</p>',
  'FAQ.Delivery.Tracking':
    '<p>Once your order is dispatched from our warehouse, you&apos;ll receive a tracking email from the courier. Please follow their instructions to track your parcel. If you haven&apos;t received a tracking email, please check your junk mailbox before contacting us.</p>',
  'FAQ.OrderIssues.GiftVouchersCancel':
    '<p>If you wish to cancel a gift voucher, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> as quickly as possible. As long as the voucher hasn&apos;t been used, we can cancel it for you.</p>',
  'FAQ.OrderIssues.OrderCancel':
    '<p>We aim to pack and ship your order as quickly as possible. If you need to cancel your order, contact us as quickly as possible on +61 2 9017 0300. We&apos;ll try our best to accommodate the cancellation, but unfortunately, we cannot guarantee that it will be possible.</p>',
  'FAQ.OrderIssues.OrderChange':
    '<p>We aim to pack and ship your order as quickly as possible. If you need to make a change to your order, contact us as quickly as possible on +61 2 9017 0300. We’ll try our best to accommodate the change but unfortunately, we cannot guarantee that every change will be possible.</p>',
  'FAQ.OrderIssues.Customs':
    '<p>If you&apos;re experiencing issues with Customs in your country, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> so that we can help you receive your order as quickly as possible.</p><p>Please note that taxes and duties may be charged for international duties. All applicable customs and import duties, fees, taxes, and any other charges are the responsibility of the customer, even if you refuse the shipment upon delivery.</p><p>Customs authorities require by law that we state the value of your order directly on the package. Customs agents have the right to release or deny release of your package and may also delay delivery in rare cases.</p>',
  'FAQ.OrderIssues.IncorrectItem':
    '<p>Unfortunately, our warehouse may occasionally send out an incorrect item. If this has happened to your order, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> so we can sort this out as quickly as possible. To expedite the process, please include your order number and a photo of the item you received, as well as the tag/barcode of the item.</p>',
  'FAQ.OrderIssues.MissingItem':
    '<p>If an item is missing from your order, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> with your order number and the name of the missing item. We will resolve this issue as quickly as possible.</p>',
  'FAQ.OrderIssues.OrderVerification':
    '<p>Hello Molly takes every measure against credit card fraud. If we suspect or our fraud system flags that your order may be fraudulent, your order will be automatically cancelled. You will receive a notice of the cancellation with the option to contact us to validate the order and have future transactions approved without hassle!</p><p>We understand this can be frustrating, but it is a rare occurrence and important for the protection of our customers and brand. Thank you for understanding.</p>',
  'FAQ.OrderIssues.OutOfStock':
    '<p>Hello Molly strives to provide high-quality products and service to its customers at an affordable price, but from time to time during the order processing stage, we may discover a manufacturing fault or inventory error with some products during dispatch.</p><p>If this happens and we are unable to fulfill the order, we reserve the right to cancel the product and issue a full refund of the product price to the customer&apos;s original payment method. The customer will be notified via email as soon as possible.</p>',
  'FAQ.OrderIssues.FaultyItem':
    '<p>If you believe your item is faulty, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> straight away with your order number, the item name, and a photo of the fault. We will sort this out as quickly as possible!</p><p>Please note that loose or excess threads and irregular prints are not considered faults as these are often natural results of the manufacturing process. Please read the description of each item carefully before purchasing.</p>',
  'FAQ.OrderIssues.PriceDifferenceRefund':
    '<p>If you see that we&apos;ve reduced the price of an item you recently ordered, unfortunately, we cannot refund the difference. Our prices change according to stock and demand from customers as well as occasional promotions. We apologize for any inconvenience.</p>',
  'FAQ.OrderIssues.ScamAndFraud':
    '<p>Unfortunately, there are a number of scam/"copycat" websites illegally using our images and pretending to sell our clothes. Please be wary of where you buy from. These sites often use our photographs and copy our names/descriptions. They are scams and if you do opt to shop with them you are likely to receive a low-quality copy of our clothing, an item that is different from what you ordered, or nothing at all. While we actively work to have such websites removed, it can be a slow process.</p><p>If you are unsure if a website is affiliated with Hello Molly, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> for advice.</p>',
  'FAQ.PaymentsAndPromos.PaymentsAndPromos':
    '<p>If your card is authorised, payment will be taken straight away and you&apos;ll receive an email confirmation for your order. If your card is not authorised, your order will not go through. You may need to contact your card issuer.</p><p>Some payment methods occasionally have additional validations and are not authorised straight away. This is most common with Paypal or Klarna. In these cases, while you would have received an order confirmation, your order will not be shipped until the payment is fully authorised. If you have concerns about the payment for your order please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> straight away so we can check for you.</p>',
  'FAQ.PaymentsAndPromos.CurrencyConversion':
    '<p>Hello Molly offers you the option to shop either in Australian Dollars or United States Dollars. To switch between the two currencies, simply toggle the currency icon at the top of the page.</p><p>If you are shopping on our Australian site, all orders are charged in Australian Dollars (AUD).</p><p>If you are shopping on our US site, all orders are charged in United States Dollars (USD). On the US site you will also be given the option to shop in your local currency thanks to our partner Global-E.</p><p>Please note if your local currency does not match the currency you are shopping in, the bank that has issued your card determines the exact exchange rate that you will be charged, but in general it should match closely with the current global rate.</p><p>For an estimate of the current rate, check <a href="http://www.xe.com/currencyconverter/" target="_blank" rel="noreferrer">XE &#150; Foreign Exchange Rate Calculator</a>.</p><p>Please note that we do not make any guarantees or promises about the current exchange rate or what you will be charged by your bank. That is out of our control.</p>',
  'FAQ.PaymentsAndPromos.NzGst':
    '<p>As of 1 December 2019, New Zealand enacted a new GST policy wherein international retails shipping to New Zealand must pay 15% tax on parcels entering the country. As a result, when you select New Zealand as your destination country at checkout, 15% will be added to your order total (excluding shipping fees).</p>',
  'FAQ.PaymentsAndPromos.Ordering':
    '<p>Once your shopping bag is full of all the items you want to purchase, you can check out by accessing your bag in the top-right corner of the screen. You will also be given the option to &apos;View Bag&apos; in a pop-up window after you have successfully added an item to your cart.</p><p>After you click &apos;Submit Order&apos; during the checkout process and your payment is successfully received, you will get a confirmation email from Hello Molly. If you checked out with your account you can also check the status of your order by logging into your account dashboard.</p>',
  'FAQ.PaymentsAndPromos.OrderPayment':
    '<p>We accept various payment methods including Visa, MasterCard, American Express, PayPal, AfterPay and Hello Molly store credit. We take your payment security very seriously so be assured that your details are safe with us.</p><p>We do not accept payment via cheque or Cash On Delivery.</p><p>We take fraud very seriously, so all credit and debit card holders are subject to validation and authorisation by both us and your card issuer.</p><p>If you&apos;re paying with a valid credit card and experience an error, please double-check that you have entered the credit card details correctly.</p><p>If you have entered the correct details and still see the error, you may wish to consider checking out with an alternative payment method or contacting your card issuer.</p>',
  'FAQ.PaymentsAndPromos.InstallmentPayments':
    '<p>We offer various payment options that allow you to pay in interest-free installments including AfterPay, Klarna and Shop Pay. If you select one of those options, you are subject to the terms and conditions of the respective payment method. In most cases, you will be charged the first instalment at the time of purchase and the remainder will be debited overtime per your agreed schedule with the payment processor.</p><p>Payment is arranged between you and the third-party payment processor. They will provide you with the full payment schedule as you complete the purchase (late fees apply if you fail to make a payment).</p><p>If you make a return, Hello Molly will issue store credit and you are still expected to complete your payments with the third-party.</p>',
  'FAQ.PaymentsAndPromos.StoreCreditPayments':
    '<p>Store credit is issued to customer accounts. If your Hello Molly account has credit on it, make sure you are logged in when you check out. On the checkout page, you will have the option to apply your credit to the order! Please ensure you do not use an express checkout method however as it will bypass the store credit option. You must proceed through our standard checkout.</p><p>You will be prompted for another payment method if your store credit doesn&apos;t cover the entire value of the order.</p><p>Also please note, accounts are not transferable between our Australian and US sites. If you have credit in Australian Dollars that you wish to use on the US site (or vice versa), please contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>If you forget to apply your store credit to your order, please contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.PaymentsAndPromos.PromoCodes':
    '<p>Occasionally we may do promotions which require you to put in the code at the shopping cart. Please note that most promotions come with exclusions which may vary but most typically promo codes cannot be applied to sale items, beauty & wellness, self love, belts, blankets, stockings, jewellery, headwear, homewares, and gift card categories. You need to enter the code exactly as it was given to you, without any spaces. If it still doesn’t work, it’s possible that the code is expired. If you continue to have trouble, please reach out via our <a href="/pages/contact-us">Contact Form</a>.</p>',
  'FAQ.PaymentsAndPromos.PromoCodeNewCustomers':
    '<p>Sign up to our email newsletter for a one-time use discount code for new customers. If you have any trouble receiving your code, please contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.ReturnsAndRefunds.Exchanges':
    '<p>Hello Molly does not offer holds or "automatic" exchanges upon return, so you will receive store credit when your return has been processed. You can use it to reorder the correct size/colour you need or another item from our amazing range if you see something else you like.</p>',
  'FAQ.ReturnsAndRefunds.Returns':
    '<p>To make a return, please follow the instructions on our Returns page <a href="/pages/returns/" target="_blank">here</a>.</p><p>Once you have registered your return, please use the prepaid label to send it back.</p><p>To send your return back using an alternative method, address all eligible returns to:</p><p>Hello Molly<br />PO Box 286<br />Strawberry Hills<br />NSW 2012<br /></p><p>We strongly advise you to retain a copy of the tracking/serial number of your return package to allow for easier tracing.</p>',
  'FAQ.ReturnsAndRefunds.Refunds':
    '<p>At this stage refunds are only offered in the case of faulty items. Change of mind returns are issued as store credit. If you feel your order qualifies for a refund, please email us at <a href="mailto:returns@hellomolly.com">returns@hellomolly.com</a>.</p><p>Refunds back to credit/debit cards can take 5-7 business days to process and appear in your account depending on your financial institution. If you still haven’t received your refund after 7 business days, please first contact your financial institution to check any pending refunds.</p><p>If there are no pending refunds for your account, please email our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>Please note that foreign transaction fees and currency conversion fees incurred during the initial order payment are charged by the respective credit card company or bank, not by Hello Molly. Hence, we cannot refund such fees.</p><p>Check out our full Return Policy <a href="/pages/returns/" target="_blank">here</a>.</p>',
  'FAQ.ReturnsAndRefunds.AfterpayReturns':
    '<p>If you wish to return something you purchased using AfterPay, the process is the same as regular returns. Unless the item is faulty, you will be issued store credit for the full amount of the unwanted item. You will continue to be charged by AfterPay until you have paid all your instalments.</p>',
  'FAQ.ReturnsAndRefunds.StoreCredit':
    '<p>Store credit is issued to accounts. If your Hello Molly account has credit on it, make sure you are logged in when you check out. Under "Payment" on the checkout page, you will have the option to apply your credit to the order!</p><p>If you make a return and don’t have an account with us, we will send an account activation email to the email address associated with your original order. Please note, accounts are not transferable between our Australian and US sites.</p><p>If you have credit in Australian Dollars that you wish to use on the US site (or vice versa), please contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.ReturnsAndRefunds.Turnaround':
    '<p>Our Returns Department tries to process all returns in a timely manner, but please allow up to 7 business days for your return to be processed once delivered to our office, particularly during busy periods such as the holidays.</p><p>Note that the turnaround time for returns may fluctuate from time to time depending on the volume of returns.</p>',
  'FAQ.ReturnsAndRefunds.HappyReturns':
    '<p>Please note Happy Returns is currently only available for customers located in the contiguous USA.</p><p>Select Happy Returns as your return solution to drop off your return at a Return Bar (9,000 locations in the USA and growing!) near you. You will be issued a QR Code to present at the Return Bar. You do not need to repackage the return as if it is being sent back. Please just ensure all your items have the original tags attached and items with individual packaging (e.g., shoe boxes) are returned with that original packaging.</p><p>Your return will be processed at the Return Bar. Please note there is a limit of 9 items per return to a Return Bar. If you wish to return more than 9 items via Happy Returns, you will need to split it into two returns and pay twice accordingly.</p>',
  'FAQ.Technical.ChangingCurrency':
    '<p>Hello Molly offers you the option to shop either in Australian Dollars or United States Dollars. To switch between the two currencies, simply toggle the currency icon at the top of the page.</p>',
  'FAQ.Technical.ChangingEmail':
    '<p>The email address provided when placing your order is the unique identifier for each individual order, so we are unable to change the email address for orders once they are submitted.</p><p>We suggest that you create an account with your preferred email address and place your orders through that account to keep track of all your new and past Hello Molly orders.</p><p>If you put down an incorrect email address you can still enquire about your order status by contacting our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.Technical.EmailNotAccepted':
    '<p>Our ordering system is able to accept all email addresses, but in the event that you have tried to order using your email address and our checkout or registration system isn&apos;t accepting it, please email our Customer Care team for assistance at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>We suggest trying an alternate email address if the first email address doesn&apos;t work or email our Customer Care Team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>Please do not deliberately misspell your email address (e.g. leaving out the com.au) to force the system to accept it as this would lead to you not receiving any emails relevant to your order.</p>',
  'FAQ.Technical.NotReceivingEmails':
    '<p>If you&apos;re not receiving emails, please ensure you check your SPAM/junk mailbox. If the email isn&apos;t there, please contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> and they will help you further.</p>',
  'FAQ.Technical.OrderConfirmationEmails':
    '<p>Order confirmation emails are sent to the email address that the customer provides at checkout.</p><p>If you haven&apos;t received your order confirmation email within an hour of placing your order, please check your SPAM/junk mailbox. If it isn&apos;t there, contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> before placing a new order.</p><p>Unfortunately, we cannot redirect order confirmation emails to different addresses once the order has been placed.</p>',
  'FAQ.Technical.LoginProblems':
    '<p>If you&apos;re having trouble logging into your account, make sure you&apos;re trying to sign in via the correct website. Accounts are not transferable between our Australian and US sites, so if you created an account on one, it will not work on the other.</p><p>If you are still having trouble, please email us at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.Technical.Unsubscribe':
    '<p>If you wish to unsubscribe from our newsletter, click the &apos;Unsubscribe&apos; link at the bottom of every email or log into your Hello Molly account and adjust your subscription preferences there.</p>',
};
