import React from 'react';

const CompactSearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.29443 7.62802L11.9977 11.3316"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41208 8.82263C7.66381 8.82263 9.4892 6.99724 9.4892 4.74551C9.4892 2.49378 7.66381 0.668396 5.41208 0.668396C3.16035 0.668396 1.33496 2.49378 1.33496 4.74551C1.33496 6.99724 3.16035 8.82263 5.41208 8.82263Z"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CompactSearchIcon;
